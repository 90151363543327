import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserMarkers, fetchUserDrawing } from '../store/actions';

const RerenderButton = props => {
  const isLogin = useSelector(state => state.login.isLogin);
  const dispatch = useDispatch();
  const reRender = () => {
    if (isLogin) {
      dispatch(fetchUserMarkers());
      dispatch(fetchUserDrawing());
    }
    return;
  }
  return (
    <div className="l-map-btn l-map-btn--reload">
      <a className="js-modal-open" onClick={() => reRender()} />
    </div>
  );
}

export default RerenderButton;
