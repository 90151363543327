import React from 'react';
import ShinsuiHanreiImg from '../assets/img/shinsui_hanrei.jpg';
import * as $ from 'jquery';

const toggleModal = () => {
    let modal = document.getElementById("modal01");
    $(modal).fadeToggle();
    return false;
};

const HanreiButton = props => {
    return (
        <div className="hanrei-shinsui">
            <a className="js-modal-open" data-target="modal01" onClick={() => toggleModal()} />
        </div>
    )
}

const HanreiModal = props => {
    return (
        <>
            <div id="modal01" className="modal js-modal">
                <div className="modal-bg js-modal-close" onClick={() => toggleModal()}></div>
                <div className="modal-content">
                    <div className="modal-inner">
                        <div>
                            <img src={ShinsuiHanreiImg} />
                        </div>
                        <a className="modal-close js-modal-close" onClick={() => toggleModal()}>
                            <span className="icon">
                                ×
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

const HanreiAsset = props => {
    return (
        <>
            <HanreiButton />
            <HanreiModal />
        </>
    );
};

export default HanreiAsset;