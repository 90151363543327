import React from 'react';
import evashelterImg from '../../assets/img/marker/m_evacuation-shelter.png';
import tameikeImg from '../../assets/img/marker/m_reservoir.png';
import { useSelector } from "react-redux";
import commonLib from "../../config/commonLib";

const MarkerHanrei = props => {
  const isLogin = useSelector(state => state.login.isLogin);
  const markerVisible = useSelector(state => state.markerVisible);
  const markerTypes = useSelector(state => state.markerData.markerTypes);
  return (
    <>
      <div className="hanrei">
        <ul className="hanrei-list">
          <li><dl><dt><span><img src={evashelterImg} /></span></dt><dd>避難所</dd></dl></li>
          {markerVisible.tameike_kurashiki &&
            <li><dl><dt><span><img src={tameikeImg} /></span></dt><dd>ため池</dd></dl></li>
          }
          {isLogin &&
            <>
              {commonLib.newSpotList.map((spot, idx) => (
                markerTypes.includes(spot.type) && <li key={idx.toString()}><dl><dt><span><img src={spot.src} /></span></dt><dd>{spot.name}</dd></dl></li>
              ))}
            </>
          }
        </ul>
      </div>
    </>
  )
}

export default MarkerHanrei;
