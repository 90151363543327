import React, { useState, useEffect, Fragment } from 'react';
import { MyAnalytics, PageTopButton } from '../atoms';
import { Footer, Header } from '../blocks';
import { useDispatch, useSelector } from "react-redux";
import { fetchUsefulLinks } from '../store/actions';

const UsefulLinks = props => {
    const linkDatas = useSelector(state => state.linkData.links);
    const [links, setLinks] = useState(linkDatas);
    const dispatch = useDispatch();
    useEffect(async () => {
        const response = await dispatch(fetchUsefulLinks())
            .then(res => {
                return res;
            }).catch(err => {
                return "error";
            });
        if (response !== "error") {
            setLinks(response);
        }
    }, []);

    return (
        <>
            <MyAnalytics />
            <Header type="normal" />
            <div className="low-container">
                <div className="low-contents">
                    <div className="low-contents-inner">
                        <h1 className="page-title">お役立ちリンク集</h1>
                        <section className="sec">
                            <h2>リンク集</h2>
                            <dl className="link-list">

                                {
                                    links.map((link, idx) => {
                                        return (
                                            <Fragment key={idx}>
                                                <dt><a href={link.url} target="_blank">{link.title}</a></dt>
                                                <dd>{link.description}</dd>
                                            </Fragment>
                                        )
                                    })
                                }

                            </dl>
                        </section>
                    </div>
                </div>
            </div>
            <Footer />
            <PageTopButton />
        </>
    )
}

export default UsefulLinks;
