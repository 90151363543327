import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const HomeButton = props => {
    let body = document.body;
    const isLogin = useSelector(state => state.login.isLogin);
    const history = useHistory();
    const jumpTo = page => {
        history.push(page);
        body.classList.remove('nav-open');
        return;
    };
    return (
        <div className="h-home">
            <a onClick={() => jumpTo(isLogin ? "/mypage" : "/")}>
                <div className="h-home-inner">
                    <span className="h-home-icon" />
                    <span className="h-home-txt">
                        ホーム
                    </span>
                </div>
            </a>
        </div>
    )
}

export default HomeButton;