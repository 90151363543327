import React from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router';
import commonLib from '../config/commonLib';

const HeaderTitle = props => {
    let body = document.body;
    const login = useSelector(state => state.login);
    const history = useHistory();
    const getTitle = () => {
        const type = props.type;
        if (type !== 'bousai') {
            const title = commonLib.headerTitle[type];
            return <>{title}</>;
        }
        return <>{login.isLogin ? `地区防災マップ (${login.userInfo.group.name})` : '防災オープンデータマップ'}</>;
    };
    const jumpTo = page => {
        history.push(page);
        body.classList.remove('nav-open');
        return;
    };
    if (props.type === "normal") {
        return (
            <div className="h-user">
                <div className="h-user-inner">
                    <a onClick={() => jumpTo("/mypage")}>
                        {login.userInfo.name}
                    </a>
                </div>
            </div>
        );
    } else {
        return (
            <div className={props.isOnly ? "header-title is-only" : "header-title"}>
                <h1>{getTitle()}</h1>
            </div>
        );
    }
}

export default HeaderTitle;