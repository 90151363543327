import axios from 'axios';
import env from '../config/env';
import commonLib from '../config/commonLib';

export const CLEAR_STATE = "CLEAR_STATE";
export const clearState = () => {
    return {
        type: CLEAR_STATE
    }
}

export const UPDATE_SHAPE_TYPE = "UPDATE_SHAPE_TYPE";
export const updateShapeType = shapeType => {
    return {
        type: UPDATE_SHAPE_TYPE,
        payload: shapeType
    }
}

export const SET_MARKER_DATA = "SET_MARKER_DATA";
export const setMarkerData = (data, category) => {
    return {
        type: SET_MARKER_DATA,
        payload: {
            data: data,
            category: category
        }
    }
}

export const TOGGLE_MARKER_VISIBLE = "TOGGLE_MARKER_VISIBLE";
export const toggleMarkerVisible = category => {
    return {
        type: TOGGLE_MARKER_VISIBLE,
        payload: category
    }
}

export const SET_CIRCLE_POSITION = "SET_CIRCLE_POSITION";
export const setCirclePosition = position => {
    return {
        type: SET_CIRCLE_POSITION,
        payload: position
    }
}

export const TOGGLE_KASEN_RANK = "TOGGLE_KASEN_RANK";
export const toggleKasenRank = rank => {
    return {
        type: TOGGLE_KASEN_RANK,
        payload: rank
    }
}

export const SET_CENTER = "SET_CENTER";
export const setCenter = center => {
    return {
        type: SET_CENTER,
        payload: center
    }
}

export const SET_ZOOM = "SET_ZOOM";
export const setZoom = zoom => {
    return {
        type: SET_ZOOM,
        payload: zoom
    }
}

export const ADD_ADDITIONAL_MARKER = "ADD_ADDITIONAL_MARKER";
export const addAdditionalMarker = markerObj => {
    return {
        type: ADD_ADDITIONAL_MARKER,
        payload: markerObj
    }
}

export const CHANGE_ADDITIONAL_MARKER = "CHANGE_ADDITIONAL_MARKER";
export const changeAdditionalMarker = (idx, markerObj) => {
    return {
        type: CHANGE_ADDITIONAL_MARKER,
        idx: idx,
        payload: markerObj
    }
}

export const REMOVE_ADDITIONAL_MARKER = "REMOVE_ADDITIONAL_MARKER";
export const removeAdditionalMarker = idx => {
    return {
        type: REMOVE_ADDITIONAL_MARKER,
        payload: idx
    }
}

export const TOGGLE_LAYER_VISIBLE = "TOGGLE_LAYER_VISIBLE";
export const toggleLayerVisible = layerType => {
    return {
        type: TOGGLE_LAYER_VISIBLE,
        payload: {
            layerType: layerType
        }
    }
}

export const CONCAT_MARKERS = "CONCAT_MARKERS";
export const concatMarkers = (markers) => {
    return {
        type: CONCAT_MARKERS,
        payload: markers
    }
}

export const CONCAT_MARKER_TYPES = "CONCAT_MARKER_TYPES";
export const concatMarkerTypes = (markerTypes) => {
    return {
        type: CONCAT_MARKER_TYPES,
        payload: markerTypes
    }
}

export const SET_LOGIN_STATE = "SET_LOGIN_STATE";
export const setLoginState = isLogin => {
    return {
        type: SET_LOGIN_STATE,
        payload: isLogin
    }
}

export const SET_USER_STATE = "SET_USER_STATE";
export const setUserState = userDic => {
    return {
        type: SET_USER_STATE,
        payload: userDic
    }
}

export const CHANGE_USER_GROUP = "CHANGE_USER_GROUP";
export const changeUserGroup = group => {
    return {
        type: CHANGE_USER_GROUP,
        payload: group
    }
}

export const SET_GROUPS_STATE = "SET_GROUPS_STATE";
export const setGroupsState = groupDic => {
    return {
        type: SET_GROUPS_STATE,
        payload: groupDic
    }
}

export const TOGGLE_HANREI_VISIBLE = "TOGGLE_HANREI_VISIBLE";
export const toggleHanreiVisible = visibility => {
    return {
        type: TOGGLE_HANREI_VISIBLE,
        payload: visibility
    }
}

// paintpolygon-control の ref
export const SET_PPC = "SET_PPC";
export const setPpc = ppc => {
    return {
        type: SET_PPC,
        payload: ppc
    }
}

// ppcのgeojson格納
export const SET_GEOJSON = "SET_GEOJSON";
export const setGeojson = geojson => {
    return {
        type: SET_GEOJSON,
        payload: geojson
    }
}

// 様式集の格納
export const SET_FORMAT_FILE_CATEGORIES = "SET_FORMAT_FILE_CATEGORIES";
export const setFormatFileCategories = categories => {
    return {
        type: SET_FORMAT_FILE_CATEGORIES,
        payload: categories
    }
}

// リンク集の格納
export const SET_LINK_DATA = "SET_LINK_DATA";
export const setLinkData = links => {
    return {
        type: SET_LINK_DATA,
        payload: links
    }
};

export const SET_MARKER_VISIBLE = "SET_MARKER_VISIBLE";
export const setMarkerVisible = (category, visibility) => {
    return {
        type: SET_MARKER_VISIBLE,
        payload: {
            category: category,
            visibility: visibility
        }
    }
}
// ログイン処理
export const login = (email, password) => async (dispatch, getState) => {
    const response = await axios.post(`${env.baseURL}/api/login`,
        {
            email: email,
            password: password
        }
    ).then(res => {
        if (res == undefined || res.status != 200) {
            return "failed";
        }
        return res.data;
    }).catch(err => {
        return "error";
    });
    if(response === "failed"){
        window.alert("ログインに失敗しました。もう一度やり直してください。");
    } else if(response === "error"){
        window.alert("ログイン中にエラーが発生しました。もう一度やり直してください。");
    } else {
        dispatch({
            type: CLEAR_STATE
        });
        const firstGroup = response.user.groupList[0];
        dispatch({
            type: SET_USER_STATE,
            payload: {
                id: response.user.id,
                email: response.user.email,
                name: response.user.name,
                username: response.user.username,
                groups: response.user.groupList,
                token: response.api_token,
                center: {
                    lat: firstGroup.lat,
                    lng: firstGroup.lng
                },
                group: firstGroup,
            }
        });
        dispatch({
            type: SET_GROUPS_STATE,
            payload: response.user.groupList
        });
        dispatch({
            type: SET_CENTER,
            payload: {
                lat: firstGroup.lat,
                lng: firstGroup.lng
            }
        });
        dispatch({
            type: SET_LOGIN_STATE,
            payload: true
        });
    }
    return response;
};
// 様式集のフェッチ
export const fetchFormatFiles = () => async (dispatch, getState) => {
    const { login } = getState();
    const token = login.userInfo.token;
    const response = await axios.get(`${env.baseURL}/api/format_files`, {
        params: {},
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }).then(res => {
        return res.data.categories;
    }).catch(err=>{
        const status = err.response.status;
        if(status === 401){
            window.alert("ログアウトしました。再度ログインしてください。");
            dispatch({
                type: SET_LOGIN_STATE,
                payload: false
            });
            dispatch({
                type: CLEAR_STATE
            });
        }
        return "error"; // 異常な場合は "error" を返却
    });
    if(response !== "error"){
        dispatch({
            type: SET_FORMAT_FILE_CATEGORIES,
            payload: response
        });
    } else {
        console.log(`Error occurred when fetching format files. `);
    }
    return response;
};
// 投稿のフェッチ
export const fetchPosts = () => async (dispatch, getState) => {
    const { login } = getState();
    const groupId = login.userInfo.group.id;
    const token = login.userInfo.token;
    const response = await axios.get(`${env.baseURL}/api/posts`, {
        params: {
            group_id: groupId
        },
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }).then(res=>{
        return res.data;
    }).catch(err=>{
        const status = err.response.status;
        if(status === 401){
            window.alert("ログアウトしました。再度ログインしてください。");
            dispatch({
                type: SET_LOGIN_STATE,
                payload: false
            });
            dispatch({
                type: CLEAR_STATE
            });
        }
        return "error"; // 異常な場合は "error" を返却
    });
    return response;
};
// お役立ちリンク集のフェッチ
export const fetchUsefulLinks = () => async (dispatch, getState) => {
    const { login } = getState();
    const token = login.userInfo.token;
    const response = await await axios.get(`${env.baseURL}/api/links`,
    {
        params: {},
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }).then(res=>{
        return res.data.links;
    }).catch(err=>{
        const status = err.response.status;
        if(status === 401){
            window.alert("ログアウトしました。再度ログインしてください。");
            dispatch({
                type: SET_LOGIN_STATE,
                payload: false
            });
            dispatch({
                type: CLEAR_STATE
            });
        }
        return "error"; // 異常な場合は "error" を返却
    });
    return response;
};
// 投稿画像のアップロード
export const uploadPostImage = params => async (dispatch, getState) => {
    const { login } = getState();
    const token = login.userInfo.token;
    const response = await axios.post(`${env.baseURL}/api/upload_post_image`,
        params,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        }).then(res=>{
            return res.data;
        }).catch(err=>{
            return "error";
        });
    return response;
};
// 投稿のアップロード
export const uploadPost = params => async (dispatch, getState) => {
    const { login } = getState();
    const groupId = login.userInfo.group.id;
    const token = login.userInfo.token;
    const response = await axios.post(`${env.baseURL}/api/posts`,
        {
            ...params,
            group_id: groupId,
        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }
    ).then(res=>{
        return res.data;
    }).catch(err=>{
        return "error";
    });
    return response;
};
// 投稿の削除
export const deletePost = id => async (dispatch, getState) => {
    const { login } = getState();
    const token = login.userInfo.token;
    const response = await axios.post(`${env.baseURL}/api/posts/${id}`,
        {
            _method: 'delete',
            group_id: login.userInfo.group.id
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    ).then(res=>{
        return;
    }).catch(err=>{
        return "error";
    })
    return response;
};
// 投稿の既読
export const comfirmPost = id => async (dispatch, getState) => {
    const { login } = getState();
    const groupId = login.userInfo.group.id;
    const token = login.userInfo.token;
    const response = await axios.post(`${env.baseURL}/api/check_post/${id}`,
        {
            group_id: groupId,
        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }
    ).then(res=>{
        return;
    }).catch(err=>{
        return "error";
    });
    return response;
};
// 固定マーカーフェッチ
export const fetchFixedMarkers = () => async (dispatch, getState) => {
    commonLib.markerCategories.map(async category => {
        const response = await axios.get(`${env.localAPI}/csv/${category}`)
        .then(res=>{
            return res.data; // 正常な場合は res.data を返却
        }).catch(err=>{
            return "error"; // 異常な場合は "error" を返却
        }); // response => res.data or "error"
        if(response !== "error"){
            dispatch({
                type: SET_MARKER_DATA,
                payload: {
                    data: response,
                    category: category
                }
            });
        } else {
            console.log(`Error occurred when fetching fixed markers. [category: ${category}]`);
        }
        return;
    })
    return;
};
// マーカー画像のアップロード
export const uploadMarkerImage = params => async (dispatch, getState) => {
    const { login } = getState();
    const token = login.userInfo.token;
    const response = await axios.post(`${env.baseURL}/api/upload_spot_image`,
        params,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        }).then(res=>{
            return res.data;
        }).catch(err=>{
            return "error";
        });
    return response;
};
// マーカーのアップロード(複数同時)
export const uploadUserMarkers = markers => async (dispatch, getState) => {
    const { login } = getState();
    const groupId = login.userInfo.group.id;
    const token = login.userInfo.token;
    const response = await axios.post(`${env.baseURL}/api/spot_info`,
        {
            group_id: groupId,
            content: JSON.stringify(markers),
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    ).then(res=>{ 
        dispatch({
            type: CONCAT_MARKERS,
            payload: markers
        });
        dispatch({
            type: TOGGLE_MARKER_VISIBLE,
            payload: "registed"
        });
        return;
    }).catch(err=>{
        console.log(`Error occurred when uploading user markers. `);
        return "error";
    });
    return response;
};
// マーカーのアップロード(単体)
export const uploadUserMarker = marker => async (dispatch, getState) => {
    const { login } = getState();
    const groupId = login.userInfo.group.id;
    const token = login.userInfo.token;
    const response = await axios.post(`${env.baseURL}/api/store_spot`,
    {
        group_id: groupId,
        spot: JSON.stringify(marker),
    },
    {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }).then(res=>{
        return res;
    }).catch(err=>{
        console.log(`Error occurred when uploading user marker. `);
        return "error";
    });
    return response;
};
// マーカーの削除(単体)
export const deleteUserMarker = id => async (dispatch, getState) => {
    const { login } = getState();
    const groupId = login.userInfo.group.id;
    const token = login.userInfo.token;
    const response = await axios.post(`${env.baseURL}/api/delete_spot`,
        {
            group_id: groupId,
            id: id,
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    ).then(res=>{
        return;
    }).catch(err=>{
        console.log(`Error occurred when deleting user marker. `);
        return "error";
    });
    return response;
};
// ユーザー登録のマーカーフェッチ
export const fetchUserMarkers = () => async (dispatch, getState) => {
    const { login } = getState(); // getState()でstateの状態を取得
    const groupId = login.userInfo.group.id;
    const token = login.userInfo.token;
    const response = await axios.get(
        `${env.baseURL}/api/spot_info`, {
            params: {
                group_id: groupId
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    ).then(res=>{
        return res.data; // 正常な場合は res.data を返却
    }).catch(err=>{
        const status = err.response.status;
        if(status === 401){
            window.alert("ログアウトしました。再度ログインしてください。");
            dispatch({
                type: SET_LOGIN_STATE,
                payload: false
            });
            dispatch({
                type: CLEAR_STATE
            });
        }
        return "error"; // 異常な場合は "error" を返却
    }); // response => res.data or "error"
    if(response !== "error"){
        const spotList = JSON.parse(response.content);
        let newMarkers = [];
        let newMarkerTypes = [];
        if (spotList) {
            spotList.map(spot=>{
                if (spot !== null && spot !== undefined) {
                    newMarkers.push(spot);
                    newMarkerTypes.push(spot.type);
                }
                return;
            })
        }
        newMarkers = [...new Set(newMarkers)];
        newMarkerTypes = [...new Set(newMarkerTypes)];
        dispatch({
            type: CONCAT_MARKERS,
            payload: newMarkers
        });
        dispatch({
            type: CONCAT_MARKER_TYPES,
            payload: newMarkerTypes
        });
    } else {
        console.log("Error occurred when fetching registered markers.");
    }
    return response;
};
// ユーザー登録の手書きgeojsonアップロード
export const uploadUserDrawing = geoJson => async (dispatch, getState) => {
    const {login } = getState();
    const groupId = login.userInfo.group.id;
    const token = login.userInfo.token;
    const response = await axios.post(`${env.baseURL}/api/free_drawing`,
        {
            group_id: groupId,
            content: JSON.stringify(geoJson),
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    ).then(res=>{
        return;
    }).catch(err=>{
        return "error";
    });
    if(response !== "error"){
        dispatch({
            type: SET_MARKER_VISIBLE,
            payload: {
                category: "freeDrawing",
                visibility: true
            }
        })
    } else {
        console.log("Error occurred when uploading free drawing.");
    }
    return response;
};
// ユーザー登録の手書きgeojsonフェッチ
export const fetchUserDrawing = () => async (dispatch, getState) => {
    const { login } = getState();
    const groupId = login.userInfo.group.id;
    const token = login.userInfo.token;
    const response = await axios.get(`${env.baseURL}/api/free_drawing`, {
        params: {
            group_id: groupId
        },
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }).then(res => {
        return res.data;
    }).catch(err => {
        return "error";
    });
    if(response !== "error"){
        const geoJson = JSON.parse(response.content);
        dispatch({type: SET_GEOJSON, payload: geoJson})
    } else {
        console.log("Error occurred when fetching free drawing");
    }
    return response;
};
// ユーザー入力のキーワードで位置情報検索
export const requestLocationInfoByWord = word => async (dispatch, getState) => {
    const response = axios.get(`https://nominatim.openstreetmap.org/search/jp/`, {
        params: {
            q: word,
            format: "json"
        }
    })
    .then(res => {
        if (res.data.length >= 1) {
            let latlng = [res.data[0].lat, res.data[0].lon];
            return latlng;
        } else {
            return "none";
        }
    })
    .catch(err => {
        return "error";
    });
    return response;
};
