import React from 'react';
import { useSelector } from 'react-redux';
import {
    TileLayer,
} from 'react-leaflet';

const Rasters = props => {
    const layers = useSelector(state => state.shape);
    return (
        <>
            {layers.flood_max &&
              <>
                  {/* 洪水浸水想定区域（想定最大規模） */}
                  {<TileLayer url="https://disaportaldata.gsi.go.jp/raster/01_flood_l2_shinsuishin_data/{z}/{x}/{y}.png" zIndex={100} />}
              </>
            }
            {layers.hightide &&
              <>
                  {/* 高潮浸水想定区域 */}
                  {<TileLayer url="https://disaportaldata.gsi.go.jp/raster/03_hightide_l2_shinsuishin_data/{z}/{x}/{y}.png" zIndex={100} />}
              </>
            }
            {layers.tsunami &&
              <>
                  {/* 津波浸水想定 */}
                  {<TileLayer url="https://disaportaldata.gsi.go.jp/raster/04_tsunami_newlegend_data/{z}/{x}/{y}.png" zIndex={100} />}
              </>
            }
            {layers.dosekiryukeikaikuiki &&
              <>
                  {/* 土砂災害警戒区域<br />（土石流） */}
                  {<TileLayer url="https://disaportaldata.gsi.go.jp/raster/05_dosekiryukeikaikuiki/{z}/{x}/{y}.png" zIndex={100} />}
              </>
            }
            {layers.kyukeishakeikaikuiki &&
              <>
                  {/* 土砂災害警戒区域<br />（急傾斜地の崩壊） */}
                  {<TileLayer url="https://disaportaldata.gsi.go.jp/raster/05_kyukeishakeikaikuiki/{z}/{x}/{y}.png" zIndex={100} />}
              </>
            }
            {layers.jisuberikeikaikuiki &&
              <>
                  {/* 土砂災害警戒区域<br />（地すべり） */}
                  {<TileLayer url="https://disaportaldata.gsi.go.jp/raster/05_jisuberikeikaikuiki/{z}/{x}/{y}.png" zIndex={100} />}
              </>
            }
            
        </>
    )
}

export default Rasters;
