import React from "react";
import { useSelector } from "react-redux";


import { MyAnalytics } from '../atoms';
import { MapBase, ShareModal } from '../components/map';
import { Header } from "../blocks";

const Map = props => {
    const mapType = props.type
    const login = useSelector(state => state.login);

    return (
        <div>
            <MyAnalytics />
            <Header type={props.printing ? "printing" : "map"} />
            {login.isLogin && props.printing && <div className="p-print-ttl">
                <h1>{login.userInfo.group.name}</h1>
            </div>}
            <MapBase className="map" type={mapType} freeDrawing={props.freeDrawing || false} printing={props.printing || false} />
            <ShareModal />
        </div>
    )
}

export default Map;
