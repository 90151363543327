import React from 'react';

const MenuButton = props => {
    return (
        <div className="gnav-toggle" onClick={() => {
            let body = document.body;
            body.classList.toggle('nav-open');
        }}>
            <span className="gnav-toggle-line gnav-toggle-line--1"></span>
            <span className="gnav-toggle-line gnav-toggle-line--2"></span>
            <span className="gnav-toggle-line gnav-toggle-line--3"></span>
            <span className="gnav-txt">メニュー</span>
        </div>
    )
}

export default MenuButton;