import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setLoginState, clearState } from '../store/actions';
import FooterLogoImg from '../assets/img/footer_logo.png';

const Footer = props => {
    let body = document.body;
    const login = useSelector(state => state.login);
    const history = useHistory();
    const navList = [
        { page: "/bousai", name: `地区防災マップ ${login.isLogin ? login.userInfo.group.name : " "}` },
        { page: "/chat", name: "関係者に連絡" },
        { page: "/bousaiplans", name: "地区防災計画様式集" },
        { page: "/links", name: "お役立ちリンク集" }
    ];
    const dispatch = useDispatch();
    const jumpTo = page => {
        history.push(page);
        body.classList.remove('nav-open');
        return;
    };
    return (
        <>
            <footer>
                <div className="footer-inner">
                    <div className="footer-inner__l">
                        <ul className="footer-nav">
                            <li>メニュー</li>
                            {navList.map(nav =>
                                <li key={nav.name}>
                                    <a onClick={() => jumpTo(nav.page)}>
                                        {nav.name}
                                    </a>
                                </li>
                            )}
                        </ul>
                        <dl className="footer-contact">
                            <dt>一般社団法人データクレイドル</dt>
                            <dd><a href="https://d-cradle.or.jp/contact/contact_register/index.html" target="_blank">ご相談・お問い合わせ</a></dd>
                        </dl>
                    </div>
                    <div className="footer-inner__r">
                        <ul className="footer-subnav">
                            <li><a onClick={() => {
                                jumpTo("/userinfo");
                            }}>ユーザー情報</a></li>
                            <li><a onClick={() => {
                                dispatch(setLoginState(false));
                                dispatch(clearState());
                                jumpTo("/login");
                            }}>ログアウト</a></li>
                        </ul>
                        <p className="footer-logo"><a href="https://dataeye.jp/" target="_blank" className="footer-logo"><img src={FooterLogoImg} alt="dataeye" /></a></p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
