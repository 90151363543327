import { useEffect } from 'react';
import { useMap } from "react-leaflet";
import L from "leaflet";
import "../../../node_modules/leaflet-paintpolygon";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDrawing, setPpc } from '../../store/actions';

const PaintPolygon = props => {
    const dispatch = useDispatch();
    const map = useMap();
    const isLogin = useSelector(state => state.login.isLogin);
    const fetch = async () => {
        const response = await dispatch(fetchUserDrawing())
            .then(res => {
                let tmpPpc = L.control.paintPolygon({
                    position: 'bottomright',
                    radius: 4,
                    minRadius: 1,
                    maxRadius: 10,
                    layerOptions: {},
                    drawOptions: {
                        weight: 1
                    },
                    eraseOptions: {
                        color: '#ff324a',
                        weight: 1
                    },
                    menu: {
                        drawErase: true,
                        size: true,
                        eraseAll: true
                    }
                }).addTo(map);
                console.log(JSON.parse(res.content));
                tmpPpc.setData(JSON.parse(res.content));
                dispatch(setPpc(tmpPpc));
                return res;
            }).catch(err => {
                return "error";
            });
    };

    useEffect(() => {
        if (isLogin) {
            fetch();
        }
    }, []);
    return null;
}

export default PaintPolygon;
