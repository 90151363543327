import React from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from "react-redux";
import { fetchUserDrawing, uploadUserDrawing } from '../store/actions';
import { MyAnalytics } from '../atoms';
import { Header } from "../blocks";
import { MapBase } from '../components/map';

const FreeDrawingMap = props => {
  const history = useHistory();
  const ppc = useSelector(state => state.ppc.ppc);
  const dispatch = useDispatch();
  const save = async () => {
    const geoJson = ppc.getData();
    const response = await dispatch(uploadUserDrawing(geoJson))
      .then(res => {
        return;
      }).catch(err => {
        return "error"
      });
    if (response !== "error") {
      await dispatch(fetchUserDrawing())
        .then(res2 => {
          history.push("/bousai");
        }).catch(err => {
          // fetchに失敗した場合
          window.alert("最新の避難ルートを反映するにはリロードしてください。")
        })
    } else {
      window.alert("エラーが発生しました。もう一度保存ボタンを押してください。")
    }
  }

  return (
    <>
      <MyAnalytics />
      <Header type="freedrawing" />
      <MapBase className="map" type={"bousai"} freeDrawing={true} printing={false} />
      <div className="footer-btn">
        <p className="footer-btn-save"><button type="button" className="c-btn c-btn-solid c-btn-xs" onClick={() => {
          save();
        }}>保存</button></p>
      </div>
    </>
  )
}

export default FreeDrawingMap;
