import React from 'react';
import { useSelector } from 'react-redux';
import {
    Marker,
    Popup,
    Tooltip,
    useMap
} from "react-leaflet";
import L from 'leaflet';
import commonLib from '../../config/commonLib';
import env from '../../config/env';

const TameikeMarkers = props => {
    const csvData = props.csvData;
    if (csvData === null) {
        return null;
    }
    if (props.visibleCtrl === false) {
        return null;
    }
    const iconUrl = new L.Icon({
        iconUrl: commonLib.markerCategory2Img["ため池"],
        iconRetinaUrl: commonLib.markerCategory2Img["ため池"],
        iconSize: [27, 27],
        popupAnchor: [0, -23]
    })
    return (
        <>
            {csvData.map((csv, idx) => {
                return (
                    <Marker position={[csv['緯度'], csv['経度']]} icon={iconUrl} draggable={false} key={"tameike" + idx.toString()}>
                        <Tooltip>
                            ため池
                        </Tooltip>
                    </Marker>
                )
            })}
        </>
    )
}

const ShoukasenMarkers = props => {
    const map = useMap()
    if (map.getZoom() < commonLib.mapConfig.zoom - 1) {
        return null;
    }
    const csvData = props.csvData;
    if (csvData === null) {
        return null;
    }
    if (props.visibleCtrl === false) {
        return null;
    }
    const iconUrl = new L.Icon({
        iconUrl: commonLib.markerCategory2Img["消火栓"],
        iconRetinaUrl: commonLib.markerCategory2Img["消火栓"],
        iconSize: [27, 27],
        popupAnchor: [0, -23]
    })
    const max_lat = map.getBounds()._northEast.lat
    const min_lat = map.getBounds()._southWest.lat
    const max_lng = map.getBounds()._northEast.lng
    const min_lng = map.getBounds()._southWest.lng
    return (
        <>
            {csvData.map((csv, idx) => {
                if (min_lat < csv['緯度'] && csv['緯度'] < max_lat && min_lng < csv['経度'] && csv['経度'] < max_lng) {
                    return (
                        <Marker position={[csv['緯度'], csv['経度']]} icon={iconUrl} draggable={false} key={"shoukasen" + idx.toString()}>
                            <Tooltip>
                                消火栓
                            </Tooltip>
                        </Marker>
                    )
                } else { return null }
            })}
        </>
    )
}

const BoukasuisouMarkers = props => {
    const csvData = props.csvData;
    if (csvData === null) {
        return null;
    }
    if (props.visibleCtrl === false) {
        return null;
    }
    const iconUrl = new L.Icon({
        iconUrl: commonLib.markerCategory2Img["防火水そう"],
        iconRetinaUrl: commonLib.markerCategory2Img["防火水そう"],
        iconSize: [27, 27],
        popupAnchor: [0, -23]
    })
    return (
        <>
            {csvData.map((csv, idx) => {

                return (
                    <Marker position={[csv['緯度'], csv['経度']]} icon={iconUrl} draggable={false} key={"boukasuisou" + idx.toString()}>
                        <Tooltip>
                            防火水そう
                        </Tooltip>
                    </Marker>
                )
            })}
        </>
    )
}

const HinanjoMarkers = props => {
    const csvData = props.csvData;
    if (csvData === null) {
        return null;
    }
    if (props.visibleCtrl === false) {
        return null;
    }
    const iconUrl = new L.Icon({
        iconUrl: commonLib["evacuationShelter"],
        iconRetinaUrl: commonLib["evacuationShelter"],
        iconSize: [27, 27],
        popupAnchor: [0, -23]
    })

    const disasters = [
        '災害種別_洪水',
        '災害種別_崖崩れ、土石流及び地滑り',
        '災害種別_高潮',
        '災害種別_地震',
        '災害種別_津波',
        '災害種別_大規模な火事',
        '災害種別_内水氾濫',
        '災害種別_火山現象',
    ];

    const getDisasters = csv => {
        let targetDisasters = [];
        disasters.forEach(disaster => {
            if (csv[disaster] === '1') {
                let titles = disaster.split('_');
                targetDisasters.push(titles[1]);
            }
        })
        return targetDisasters;
    }

    return (
        <>
            {csvData.map((csv, idx) => {
                let targetDisasters = getDisasters(csv);
                return (
                    <Marker position={[csv['緯度'], csv['経度']]} icon={iconUrl} draggable={false} key={"hinanjo" + idx.toString()}>
                        <Tooltip>
                            {csv['名称']}
                        </Tooltip>
                        <Popup autoPan={false} minWidth={500} closeOnClick className="customPopup">
                            <h1 className='modal-title'>{csv['名称']}</h1>
                            <div>
                                <dl className='dl-list is-modal-spot'><dt>所在地</dt><dd>{csv['住所']}</dd></dl>
                                <dl className='dl-list is-modal-spot'><dt>災害種別</dt><dd>{targetDisasters.join(',')}</dd></dl>
                                <dl className='dl-list is-modal-spot'><dt>想定収容人数</dt><dd>{csv['想定収容人数'] + "人"}</dd></dl>
                                {/* <dl className='dl-list is-modal-spot'><dt>開設状況</dt><dd>{csv['開設状況'] === '1' ? '開設中' : '閉設中'}</dd></dl> */}
                            </div>
                            <div className='popup-btn'>
                                <a className='c-btn c-btn-solid c-btn-xs' target='_blank' rel="noreferrer" href={'https://maps.google.com/maps?saddr=現在地&daddr=' + csv['緯度'] + ',' + csv['経度']}>
                                    行き方を調べる(GoogleMapが開きます)
                                </a>
                            </div>
                        </Popup>
                    </Marker>
                )
            })}
        </>
    )
}

const Markers = props => {
    const markerData = useSelector(state => state.markerData);
    const markerVisible = useSelector(state => state.markerVisible);
    const markersInUse = commonLib.markersInUse[props.type];
    return (
        <>
            {markersInUse.indexOf("tameike_kurashiki") >= 0 && <TameikeMarkers csvData={markerData.tameike_kurashiki} visibleCtrl={markerVisible.tameike_kurashiki} />}
            {markersInUse.indexOf("shoukasen") >= 0 && <ShoukasenMarkers csvData={markerData.shoukasen} visibleCtrl={markerVisible.commonShoukasen} />}
            {markersInUse.indexOf("boukasuisou") >= 0 && <BoukasuisouMarkers csvData={markerData.boukasuisou} visibleCtrl={markerVisible.commonBoukasuisou} />}
            {markersInUse.indexOf("hinanjo") >= 0 && <HinanjoMarkers csvData={markerData.hinanjo} visibleCtrl={markerVisible.hinanjo} />}
        </>
    )
}

export default Markers;
