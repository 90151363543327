import React from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import {
    Map,
    Index,
    SpotRegist,
    Login,
    MyPage,
    UserInfo,
    BousaiPlans,
    UsefulLinks,
    ChatBoard,
    ChatMap,
    FreeDrawingMap
} from '../screens';

const MyRouter = props => {
    const isLogin = useSelector(state => state.login.isLogin);
    return (
        <>
            <Router basename={process.env.PUBLIC_URL}>
                <Route exact path='/'>
                    {isLogin ? <Redirect to="/mypage" /> : <Index />}
                </Route>
                <Route path='/bousai' render={() => <Map type="bousai" freeDrawing={false} printing={false} />} />
                <Route path='/print' render={() => <Map type="bousai" freeDrawing={false} printing={true} />} />
                <Route path='/spotRegist'>
                    {isLogin ? <SpotRegist /> : <Redirect to="/bousai" />}
                </Route>
                <Route path='/login'>
                    {isLogin ? <Redirect to="/mypage" /> : <Login />}
                </Route>
                <Route path='/mypage'>
                    {isLogin ? <MyPage /> : <Redirect to="/" />}
                </Route>
                <Route path='/userinfo'>
                    {isLogin ? <UserInfo /> : <Redirect to="/" />}
                </Route>
                <Route path='/bousaiplans'>
                    {isLogin ? <BousaiPlans /> : <Redirect to="/" />}
                </Route>
                <Route path='/links'>
                    {isLogin ? <UsefulLinks /> : <Redirect to="/" />}
                </Route>
                <Route path='/chat'>
                    {isLogin ? <ChatBoard /> : <Redirect to="/" />}
                </Route>
                <Route path='/chatMap'>
                    {isLogin ? <ChatMap /> : <Redirect to="/" />}
                </Route>
                <Route path='/freeDrawingMap'>
                    {isLogin ? <FreeDrawingMap /> : <Redirect to="/" />}
                </Route>
            </Router>
        </>
    )
}

export default MyRouter;
