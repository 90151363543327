import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadMarkerImage, uploadUserMarker, deleteUserMarker, fetchUserMarkers, setMarkerData } from '../store/actions';
import { MapContainer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { CurrentPosButton, MyAnalytics, RerenderButton } from '../atoms';
import { MapController } from '../components/map';
import env from '../config/env';
import commonLib from '../config/commonLib';
import { Header, SearchAsset } from "../blocks";
import { v4 as uuidv4 } from 'uuid';

const SpotRegist = props => {
    const centerState = useSelector(state => state.center);
    const markerData = useSelector(state => state.markerData);
    const additionals = markerData.additionals;
    const ref = useRef(null);
    const dispatch = useDispatch();
    const onPostForm = async (data, idx, marker) => {
        try {
            const params = new FormData();
            Object.keys(data).forEach(function (key) {
                params.append(key, this[key]);
            }, data);
            const response = await dispatch(uploadMarkerImage(params))
                .then(res => {
                    return res;
                }).catch(err => {
                    return "error";
                });
            if (response !== "error") {
                let newMarker = { ...marker };
                newMarker["file_path"] = response.file_path;
                newMarker["file_url"] = `${env.baseURL}/storage/${response.file_path}`;
                let newMarkers = [...additionals];
                newMarkers[idx] = newMarker;
                dispatch(setMarkerData(newMarkers, "additionals"));
            } else {
                console.log("Error occurred when uploading marker image.");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onChangeHandler = (e, idx, marker) => {
        e.preventDefault();
        onPostForm({
            image: ref.current.files[0],
        }, idx, marker);
    };

    const markerDragEnd = (idx, marker) => {
        let newMarkers = [...additionals];
        newMarkers[idx] = marker;
        dispatch(setMarkerData(newMarkers, "additionals"));
    }

    const setMarkerName = (idx, marker, name) => {
        let newMarker = { ...marker };
        newMarker["name"] = name;
        let newMarkers = [...additionals];
        newMarkers[idx] = newMarker;
        dispatch(setMarkerData(newMarkers, "additionals"));
    }

    const setMarkerNum = (idx, marker, category) => {
        let newMarker = { ...marker };
        newMarker["category"] = category;
        let newMarkers = [...additionals];
        newMarkers[idx] = newMarker;
        dispatch(setMarkerData(newMarkers, "additionals"));
    }

    const removeMarker = async (idx, marker) => {
        const response = await dispatch(deleteUserMarker(marker.id))
            .then(res => {
                return res;
            }).catch(err => {
                return "error";
            });
        if (response !== "error") {
            dispatch(fetchUserMarkers());
            let closeBtn = document.getElementsByClassName("leaflet-popup-close-button");
            closeBtn[0].click();
        } else {
            window.alert("スポットの削除に失敗しました。");
        }
    }

    const addMarker = spot => {
        let newMarkers = [...additionals];
        if (spot.type === "n01") {
            newMarkers = newMarkers.concat([{
                id: uuidv4(),
                type: spot.type,
                name: spot.name,
                category: 1,
                center: centerState.center,
                file_path: null
            }])
        } else {
            newMarkers = newMarkers.concat([{
                id: uuidv4(),
                type: spot.type,
                name: spot.name,
                center: centerState.center,
                file_path: null
            }])
        }
        dispatch(setMarkerData(newMarkers, "additionals"));
    }

    const saveMarker = async marker => {
        const response = await dispatch(uploadUserMarker(marker))
            .then(res => {
                return res;
            }).catch(err => {
                return "error";
            });
        if (response !== "error") {
            dispatch(fetchUserMarkers());
            let closeBtn = document.getElementsByClassName("leaflet-popup-close-button");
            closeBtn[0].click();
        } else {
            window.alert("スポットの保存に失敗しました。");
        }
    }

    useEffect(() => {
        dispatch(fetchUserMarkers());
    }, []);

    return (
        <div>
            <MyAnalytics />
            <Header type="spotregist" />
            <main className="main map is-first-layers-icon">
                <div className="main-inner">
                    <div className="spot-regist">
                        <div className="spot-regist-inner">
                            <dl className="spot-step">
                                <dt><span className="spot-step-num">1</span></dt>
                                <dd>登録するマークを押してください。</dd>
                            </dl>
                            <div className="spot-slider">
                                <ul className="spot-regist-icon-list">
                                    {commonLib.newSpotList.map(spot => (
                                        <li key={spot.type}><button className="spot-regist-btn" onClick={() => addMarker(spot)}><span className="spot-regist-btn_icon"><img src={spot.src} /></span><span className="spot-regist-btn_text">{spot.name}</span></button></li>
                                    ))}
                                </ul>
                            </div>
                            <dl className="spot-step">
                                <dt><span className="spot-step-num">2</span></dt>
                                <dd>地図上に表示されたマークの位置を調節してください。地図上のマークを押すとメモや写真も登録できます。<br className="u-hidden_sp" /><span className="spot-step-cmt">※1と2を繰り返すことで、複数登録が可能です</span></dd>
                            </dl>

                        </div>
                    </div>
                    <MapContainer
                        tap={false}
                        id="map"
                        minZoom={commonLib.mapConfig.minZoom}
                        zoom={centerState.zoom || commonLib.mapConfig.zoom}
                        maxZoom={commonLib.mapConfig.maxZoom}
                        center={centerState.center || commonLib.mapConfig.center}
                        gestureHandling={commonLib.mapConfig.gestureHandling}
                        preferCanvas={commonLib.mapConfig.preferCanvas}
                        zoomControl={commonLib.mapConfig.zoomControl}
                        scrollWheelZoom={commonLib.mapConfig.scrollWheelZoom}
                    >
                        <MapController />
                        {additionals.map((marker, idx) => {
                            if (marker === null || marker === undefined) {
                                return null;
                            }
                            let center = [marker.center.lat, marker.center.lng];
                            let iconUrl = null;
                            if (marker.type === "n01") {
                                let category = "";
                                if (marker.category < 10) {
                                    category = `n0${marker.category}`
                                } else {
                                    category += `n${marker.category}`
                                }
                                iconUrl = new L.Icon({
                                    iconUrl: commonLib[`${marker.file_url ? category + "P" : category}`],
                                    iconRetinaUrl: commonLib[`${marker.file_url ? category + "P" : category}`],
                                    iconSize: [32, 32],
                                    popupAnchor: [0, -50]
                                });
                            } else {
                                iconUrl = new L.Icon({
                                    iconUrl: commonLib[`${marker.file_url ? marker.type + "P" : marker.type}`],
                                    iconRetinaUrl: commonLib[`${marker.file_url ? marker.type + "P" : marker.type}`],
                                    iconSize: [32, 32],
                                    popupAnchor: [0, -50]
                                });
                            }
                            return (
                                <Marker
                                    position={center}
                                    icon={iconUrl}
                                    draggable={true}
                                    key={marker.type + idx.toString()}
                                    eventHandlers={{
                                        dragend: (e) => {
                                            marker.center = e.target._latlng;
                                            markerDragEnd(idx, marker)
                                        }
                                    }}
                                >
                                    <Popup autoPan={false}>
                                        <form>
                                            <div className="pb-sm1">
                                                <dl className="dl-list is-modal-spot is-first">
                                                    <dt>スポット名</dt>
                                                    <dd><input type="text" className="f-w-lg"
                                                        value={marker.name}
                                                        onChange={e => setMarkerName(idx, marker, e.target.value)}
                                                        placeholder="例：空き家" /></dd>
                                                </dl>
                                                {marker.name === "その他" && <>
                                                    <dl className="dl-list is-modal-spot is-first">
                                                        <dt>カテゴリ</dt>
                                                        <dd>
                                                            <select value={marker.category} name="choice" onChange={e => setMarkerNum(idx, marker, e.target.value)}>
                                                                <option value={1}>1</option>
                                                                <option value={2}>2</option>
                                                                <option value={3}>3</option>
                                                                <option value={4}>4</option>
                                                                <option value={5}>5</option>
                                                                <option value={6}>6</option>
                                                                <option value={7}>7</option>
                                                                <option value={8}>8</option>
                                                                <option value={9}>9</option>
                                                                <option value={10}>10</option>
                                                                <option value={11}>11</option>
                                                                <option value={12}>12</option>
                                                                <option value={13}>13</option>
                                                                <option value={14}>14</option>
                                                                <option value={15}>15</option>
                                                                <option value={16}>16</option>
                                                                <option value={17}>17</option>
                                                                <option value={18}>18</option>
                                                                <option value={19}>19</option>
                                                                <option value={20}>20</option>
                                                            </select>
                                                        </dd>
                                                    </dl>
                                                </>}
                                                <dl className="dl-list is-modal-spot is-first">
                                                    <dt>写真投稿(任意)</dt>
                                                    <dd><label><input type='file' onChange={(e) => { onChangeHandler(e, idx, marker) }} ref={ref}
                                                        className='fileinput' /></label>
                                                        <br />
                                                        {marker.file_url && <img src={marker.file_url} width={200} alt='icon' />}
                                                    </dd>
                                                </dl>
                                            </div>
                                            <ul className="submit-side">
                                                <li>
                                                    <button className="c-btn c-btn-solid c-btn-xs" type="button" onClick={() => saveMarker(marker)}>保存する</button>
                                                </li>
                                                <li>
                                                    <button className="c-btn c-btn-solid c-btn-xs" type="button" onClick={() => removeMarker(idx, marker)}>削除する</button>
                                                </li>
                                            </ul>
                                        </form>
                                    </Popup>
                                </Marker>
                            );
                        })}
                        <CurrentPosButton />
                        <RerenderButton />
                        <SearchAsset />
                    </MapContainer>
                </div>
            </main>
        </div>
    )
}

export default SpotRegist;
