import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toggleHanreiVisible } from '../store/actions';

const PrintMenu = props => {
    const hanreiState = useSelector(state => state.hanrei);
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <div className="h-map">
            <form>
                <ul className="h-map-print f-radio">
                    <li>凡例：</li>
                    <li>
                        <input type="radio" id="radio01" name="demo01"
                            checked={hanreiState.visibility}
                            onChange={e => dispatch(toggleHanreiVisible(true))}
                        />
                        <label htmlFor="radio01">
                            有
                        </label>
                    </li>
                    <li>
                        <input type="radio" id="radio02" name="demo01"
                            checked={!hanreiState.visibility}
                            onChange={e => dispatch(toggleHanreiVisible(false))}
                        />
                        <label htmlFor="radio02">
                            無
                        </label>
                    </li>
                    <li>
                        <button type="button" className="c-btn c-btn-gray c-btn-xs"
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            キャンセル
                        </button>
                    </li>
                    <li>
                        <button type="button" className="c-btn c-btn-green c-btn-xs"
                            onClick={() => {
                                window.print();
                                return false;
                            }}
                        >
                            印刷する
                        </button>
                    </li>
                </ul>
            </form>
        </div>
    );
};

export default PrintMenu;