import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMarkerVisible, setMarkerVisible } from '../../store/actions'
import { useHistory } from 'react-router';
import commonLib from '../../config/commonLib';
import * as $ from 'jquery';
import evashelterImg from '../../assets/img/marker/m_evacuation-shelter.png';
import tameikeImg from '../../assets/img/marker/m_reservoir.png';
import shoukasenImg from '../../assets/img/marker/m_shoukasen.png';
import boukasuisouImg from '../../assets/img/marker/m_boukasuisou.png';

const NewSpots = props => {
    const isLogin = useSelector(state => state.login.isLogin);
    const markerVisible = useSelector(state => state.markerVisible);
    const markerTypes = useSelector(state => state.markerData.markerTypes);
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <>
            <p className="gnav-sub-ttl">スポット</p>
            <div className="gnav-list-ac">
                <p className="f-check"><input type="checkbox" id="cbs01" checked={markerVisible.hinanjo} onChange={() => dispatch(toggleMarkerVisible("hinanjo"))} /><label htmlFor="cbs01"><span className="icon-marker"><img src={evashelterImg} /></span>避難所</label></p>
            </div>
            {/*<div className="gnav-list-ac">*/}
            {/*    <p className="f-check"><input type="checkbox" id="cbs02" checked={markerVisible.tameike_kurashiki} onChange={() => dispatch(toggleMarkerVisible("tameike_kurashiki"))} /><label htmlFor="cbs02"><span className="icon-marker"><img src={tameikeImg} /></span>ため池</label></p>*/}
            {/*</div>*/}
            <div className="gnav-list-ac">
                <p className="f-check"><input type="checkbox" id="cbs03" checked={markerVisible.commonShoukasen} onChange={() => dispatch(toggleMarkerVisible("commonShoukasen"))} /><label htmlFor="cbs03"><span className="icon-marker"><img src={shoukasenImg} /></span>消火栓</label></p>
            </div>
            <div className="gnav-list-ac">
                <p className="f-check"><input type="checkbox" id="cbs04" checked={markerVisible.commonBoukasuisou} onChange={() => dispatch(toggleMarkerVisible("commonBoukasuisou"))} /><label htmlFor="cbs04"><span className="icon-marker"><img src={boukasuisouImg} /></span>防火水そう</label></p>
            </div>
            { isLogin &&
                <>
                    <div className="gnav-list-ac u-pb-xs">
                        <p className="f-check"><input type="checkbox" id="cbmy-00" checked={markerVisible.regist} onChange={() => dispatch(toggleMarkerVisible("regist"))} /><label htmlFor="cbmy-00">登録したスポット</label></p>
                        <div className="ac-item" id="ac-item-spots" onClick={(e) => {
                            e.preventDefault();
                            let acItem = document.getElementById("ac-item-spots")
                            if ($(acItem).hasClass('is-active')) {
                                $(acItem).removeClass('is-active');
                                $(acItem).next()
                                    .stop()
                                    .slideUp(300);
                            } else {
                                $(acItem).addClass('is-active');
                                $(acItem).next()
                                    .stop()
                                    .slideDown(300);
                            }
                        }}></div>
                        <ul id="ac-item-ul">
                            {commonLib.newSpotList.map((spot) => (
                                markerTypes.includes(spot.type) && <li key={spot.id}><p className="f-check"><input type="checkbox" checked={markerVisible[spot.type]} id={spot.id} onChange={() => dispatch(setMarkerVisible(spot.type, !markerVisible[spot.type]))} /><label htmlFor={spot.id}><span className="icon-marker"><img src={spot.src} /></span>{spot.name}</label></p></li>
                            ))}
                        </ul>
                    </div>
                    <div className="gnav-spot-btn">
                        <a className="c-btn c-btn-spot" onClick={() => history.push("/spotRegist")}>スポットを登録</a>
                    </div>
                </>
            }



          <p className="gnav-sub-ttl">出典</p>
          <div className="gnav-list-ac">
            <p className="">ハザードマップポータルサイト</p>
          </div>
          <div className="gnav-list-ac">
            <p>東京都オープンデータカタログサイト</p>
          </div>

        </>
    )
}

export default NewSpots;
