import React from 'react';
import { useMap } from 'react-leaflet';
import { useGeolocation } from 'react-use';

const CurrentPosButton = props => {
    const map = useMap();
    const geoLocation = useGeolocation();
    const currentPos = () => {
        if (geoLocation.latitude && geoLocation.longitude) {
            map.panTo([geoLocation.latitude, geoLocation.longitude]);
        } else {
            window.alert("位置情報を取得中です。少し時間を置いてもう一度試してください。");
        }
    }
    return (
        <div className="l-map-btn l-map-btn--here">
            <a className="js-modal-open"
                onClick={() => currentPos()}
            />
        </div>
    );
}

export default CurrentPosButton;
