import React from 'react';
import * as $ from 'jquery';
import QRImg from '../../assets/img/qr.png'

const ShareModal = props => {
    return (
        <>
            <div id="modal03" className="modal js-modal">
                <div className="modal-bg js-modal-close" onClick={() => {
                    let modal = document.getElementById("modal03");
                    $(modal).fadeOut();
                    return false;
                }}></div>
                <div className="modal-content">
                    <div className="modal-inner">
                        <div className="u-txt-c">
                            <h2 className="modal-title">地図を共有する</h2>
                            <p className="u-pb-sm">QRコードをスマートフォンのアプリでスキャンしてください。</p>
                            <p><img src={QRImg} /></p>
                        </div>
                        <a className="modal-close js-modal-close" onClick={() => {
                            let modal = document.getElementById("modal03");
                            $(modal).fadeOut();
                            return false;
                        }}><span className="icon">×</span></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShareModal;