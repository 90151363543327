import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { MyAnalytics, PageTopButton } from '../atoms';
import { Footer, Header } from '../blocks';
import env from "../config/env";
import commonLib from '../config/commonLib';
import {
    fetchPosts,
    uploadPostImage,
    uploadPost,
    deletePost,
    comfirmPost
} from "../store/actions";
import {
    Marker,
    useMapEvents,
    MapContainer,
    ScaleControl,
    TileLayer
} from "react-leaflet";
import L from "leaflet";
import pinIcon from '../assets/img/icon_pin.png';
import markerIcon from '../assets/img/icon_pin2.png';
import cameraIcon from '../assets/img/icon_camera.png';
import userIcon from '../assets/img/icon_user.png';
import eyeIcon from '../assets/img/icon_eye.png';


const regex = /([\n\r])/g
const nl2br = (str) => {
    return str.split(regex).map(line => {
        return line.match(regex) ? <br /> : line
    })
}

// TODO OGASAWARA
// stateを使わずreduxで完結させたい。
const ChatBoard = props => {
    const login = useSelector(state => state.login);
    const [members, setMembers] = useState([]);
    const [posts, setPosts] = useState([]);
    const [newPostContent, setNewPostContent] = useState("");
    const [file_url, setFileUrl] = useState(null);
    const [file_path, setFilePath] = useState(null);
    const ref = useRef(null);
    const [mapUsed, setMapUsed] = useState(false);
    const [checkRequired, setCheckRequired] = useState(false);
    const [markerLat, setMarkerLat] = useState(login.userInfo.center.lat);
    const [markerLng, setMarkerLng] = useState(login.userInfo.center.lng);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(async () => {
        const response = await dispatch(fetchPosts())
            .then(res => {
                return res;
            }).catch(err => {
                return "error"
            });
        if (response !== "error") {
            setMembers(response.group.users);
            setPosts(response.posts);
        } else {
            console.log("Error occurred when fetching posts.")
        }
    }, []);

    const onPostForm = async (data) => {
        try {
            const params = new FormData();
            Object.keys(data).forEach(function (key) {
                params.append(key, this[key]);
            }, data);
            const response = await dispatch(uploadPostImage(params))
                .then(res => {
                    return res;
                }).catch(err => {
                    return "error";
                });
            if (response !== "error") {
                setFilePath(response.file_path);
                setFileUrl(`${env.baseURL}/storage/${response.file_path}`);
            } else {
                console.log("Error occurred when uploading post image.");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onChangeHandler = e => {
        e.preventDefault();
        onPostForm({
            image: ref.current.files[0],
        });
    };

    const iconUrl = new L.Icon({
        iconUrl: markerIcon,
        iconRetinaUrl: markerIcon,
        iconSize: [29, 47],
        popupAnchor: [0, -50]
    });

    const saveNewPost = async () => {
        if (newPostContent == '') {
            alert("内容を入力してください")
            return
        }
        const response = await dispatch(uploadPost({
            content: newPostContent,
            file_path: file_path,
            map_used: mapUsed,
            lat: markerLat,
            lng: markerLng,
            check_required: checkRequired,
        })).then(res => {
            return res;
        }).catch(err => {
            return "error";
        })
        if (response !== "error") {
            let newPosts = [...posts];
            newPosts.unshift(response);
            setPosts(newPosts);
            setNewPostContent('')
            setFilePath(null)
            setFileUrl(null)
            setMapUsed(false)
        } else {
            console.log("Error occurred when uploading post.");
        }
    }

    const deletePostById = async post => {
        const response = await dispatch(deletePost(post.id))
            .then(res => {
                return;
            }).catch(err => {
                return "error";
            });
        if (response !== "error") {
            let newPosts = [];
            for (let i = 0; i < posts.length; i++) {
                const tmp = posts[i]
                if (tmp.id != post.id) {
                    newPosts.push(tmp);
                }
            }
            setPosts(newPosts);
        } else {
            console.log("Error occurred when deleting post.");
        }
    }

    const checkPost = async (post, idx) => {
        const response = await dispatch(comfirmPost(post.id))
            .then(res => {
                return;
            }).catch(err => {
                return "error";
            });
        if (response !== "error") {
            let newPosts = [...posts];
            newPosts[idx].check_users.push(
                {
                    id: login.userInfo.id,
                    name: login.userInfo.name,
                }
            )
            setPosts(newPosts);
        } else {
            console.log("Error occurred when comfirming post.");
        }
    }

    const MyComponent = () => {
        const map = useMapEvents({
            drag: () => {
                const center = map.getCenter()
                setMarkerLat(center.lat);
                setMarkerLng(center.lng);
            }
        })
        return null
    }

    return (
        <>
            <MyAnalytics />
            <Header type="normal" />
            <div className="low-container">
                <div className="low-contents is-timeline">
                    <section className="tl-title"><h1>関係者に連絡</h1></section>
                    <div className="timeline">
                        <div className="timeline-l">
                            <h4 className="tl-user-name-ti">グループメンバー</h4>
                            <ul className="tl-user-list">
                                {members.map((member, idx) => {
                                    return (
                                        <li key={idx}>
                                            <dl className="tl-user-name">
                                                <dt><span className="icon-marker2"><img src={userIcon} /></span>
                                                </dt>
                                                <dd>{member.name}</dd>
                                            </dl>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="timeline-r">
                            <div className="tl-input">
                                <form>
                                    <div>
                                        <textarea required value={newPostContent} onChange={e => setNewPostContent(e.target.value)} placeholder="連絡することを入力" />
                                    </div>
                                    {file_url &&
                                        <div>
                                            <dl className="dl-list is-modal-spot is-first">
                                                <dt>&nbsp;</dt>
                                                <dd><label></label>
                                                    <br />
                                                    <img src={file_url} width={200} alt='icon' />
                                                </dd>
                                            </dl>
                                        </div>
                                    }
                                    <div>
                                        {mapUsed &&
                                            <MapContainer
                                                tap={false}
                                                id="small-map"
                                                minZoom={commonLib.mapConfig.minZoom}
                                                zoom={commonLib.mapConfig.zoom}
                                                maxZoom={commonLib.mapConfig.maxZoom}
                                                center={login.userInfo.center}
                                                gestureHandling={commonLib.mapConfig.gestureHandling}
                                                preferCanvas={commonLib.mapConfig.preferCanvas}
                                                zoomControl={commonLib.mapConfig.zoomControl}
                                                scrollWheelZoom={commonLib.mapConfig.scrollWheelZoom}
                                            >
                                                <MyComponent />
                                                <TileLayer
                                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <ScaleControl position="bottomleft" imperial={false} />
                                                <Marker
                                                    icon={iconUrl}
                                                    position={[markerLat, markerLng]}>
                                                </Marker>
                                            </MapContainer>
                                        }
                                    </div>
                                    <div>
                                        <ul className="tl-submit">
                                            <li>
                                                <button onClick={(e) => { setMapUsed(!mapUsed) }} className="c-btn-tl-spot" type="button">
                                                    <img src={pinIcon} title="位置情報を選択" /></button>
                                                <label>
                                                    <span className="tl-filelabel" title="写真を選択">
                                                        <img src={cameraIcon} title="写真を選択" /></span>
                                                    <input type='file' name="datafile" id="filesend" onChange={(e) => { onChangeHandler(e) }} ref={ref}
                                                        className='fileinput' />
                                                </label>
                                            </li>
                                            <li>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={checkRequired}
                                                        onChange={(e) => { setCheckRequired(e.target.checked) }} />
                                                    既読確認をしますか？
                                                </label>

                                            </li>

                                            <li><button onClick={() => saveNewPost()} type="button" className="c-btn c-btn-green c-btn-xs">投稿</button></li>
                                        </ul>
                                    </div>
                                </form>
                            </div>
                            {posts.map((post, idx) => {
                                return (
                                    <div className="tl-post" key={idx}>
                                        <dl className="tl-post-inner">
                                            <dt><img src={userIcon} /></dt>
                                            <dd>
                                                <div className="tl-post-head">
                                                    <ul className="tl-user">
                                                        <li>{post.user.name}</li>
                                                        <li>{post.published_at}</li>
                                                    </ul>
                                                    <ul className="tl-del">
                                                        {(post.user_id == login.userInfo.id && post.check_required == 1) &&
                                                            <>
                                                                {/* 閲覧ユーザーのcheck_usersは自身を含んでいるのでマイナス1 */}
                                                                <li className="tl-del_read"><span><img src={eyeIcon}
                                                                    alt="既読数" /></span><span>{post.check_users != undefined ? (post.check_users.length - 1) : 0}</span>
                                                                </li>
                                                            </>
                                                        }
                                                        {/* 自身のデータのみ削除可能 */}
                                                        {(post.user_id == login.userInfo.id) &&
                                                            <li>
                                                                <button onClick={() => deletePostById(post)} type="button" className="tl-btn-del js-modal-open"
                                                                    data-target="modal02"></button>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="tl-txt">
                                                    {post.file_url && <><img src={post.file_url} width={200} alt='icon' /><br /></>}
                                                    {nl2br(post.content)}
                                                    {post.lat && <>
                                                        <a onClick={() => {
                                                            history.push({
                                                                pathname: '/chatMap',
                                                                postData: post,
                                                            })
                                                        }}>地図で確認する</a>
                                                    </>
                                                    }
                                                    <br />
                                                    {/* 自分以外の投稿 && 既読確認 && 自分が未読 */}
                                                    {(post.user_id != login.userInfo.id
                                                        && post.check_required == 1
                                                        && (post.check_users == undefined || !post.check_users.find(tmpUser => { return tmpUser.id == login.userInfo.id }))
                                                    ) &&
                                                        <>
                                                            <div className="tl-read">
                                                                <p>メッセージを確認しましたか？</p>
                                                                <p><button onClick={() => checkPost(post, idx)} type="button" className="c-btn-tl-confirm">はい</button></p>
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <PageTopButton />
        </>
    )
}

export default ChatBoard;
