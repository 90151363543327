import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLayerVisible, fetchFixedMarkers } from '../../store/actions';
import * as $ from 'jquery';
import m_naisui_1hi from '../../assets/img/marker/m_naisui_1hi.png';
import m_naisui_045hi from '../../assets/img/marker/m_naisui_045hi.png';
import m_naisui_02hi from '../../assets/img/marker/m_naisui_02hi.png';
import m_naisui_02low from '../../assets/img/marker/m_naisui_02low.png';
import m_shinsui_5hi from '../../assets/img/marker/m_shinsui_5hi.png';
import m_shinsui_5low from '../../assets/img/marker/m_shinsui_5low.png';
import m_shinsui_3low from '../../assets/img/marker/m_shinsui_3low.png';
import m_shinsui_05low from '../../assets/img/marker/m_shinsui_05low.png';
import m_s_caution_area from '../../assets/img/marker/m_s-caution-area.png';
import m_caution_area from '../../assets/img/marker/m_caution-area.png';

const NewLayerOptions = props => {
    const layers = useSelector(state => state.shape);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchFixedMarkers());
    }, [dispatch]);
    
    const toggleAccordion = (e, accordionId) => {
        e.preventDefault();
        let acItem = document.getElementById(accordionId)
        if ($(acItem).hasClass('is-active')) {
            $(acItem).removeClass('is-active');
            $(acItem).next()
            .stop()
            .slideUp(300);
        } else {
            $(acItem).addClass('is-active');
            $(acItem).next()
            .stop()
            .slideDown(300);
        }
    }
    
    return (
        <>
            <p className="gnav-sub-ttl is-first">レイヤー</p>

            <div className="gnav-list-ac">
                <p className="f-check">
                    <input type="checkbox" id="cb01-flood_max" checked={layers.flood_max} onChange={e => dispatch(toggleLayerVisible("flood_max"))} />
                    <label htmlFor="cb01-flood_max">洪水浸水想定区域<br />（想定最大規模）</label></p>
                <div className="ac-item ac-hanrei" id="ac-flood_max" onClick={(e) => {toggleAccordion(e, "ac-flood_max")}}></div>
                <ul>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-kouzui-lv1"></span><span className="icon-name">0〜0.5m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-kouzui-lv2"></span><span className="icon-name">0.5〜3.0m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-kouzui-lv3"></span><span className="icon-name">3.0〜5.0m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-kouzui-lv4"></span><span className="icon-name">5.0〜10m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-kouzui-lv5"></span><span className="icon-name">10〜20m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-kouzui-lv6"></span><span className="icon-name">20m〜</span>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="gnav-list-ac">
                <p className="f-check">
                    <input type="checkbox" id="cb02-tide" checked={layers.hightide} onChange={e => dispatch(toggleLayerVisible("hightide"))} />
                    <label htmlFor="cb02-tide">高潮浸水想定区域</label></p>
                <div className="ac-item ac-hanrei" id="ac-hightide" onClick={(e) => {toggleAccordion(e, "ac-hightide")}}></div>
                <ul>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-takashio-lv1"></span><span className="icon-name">0〜0.5m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-takashio-lv2"></span><span className="icon-name">0.5〜3.0m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-takashio-lv3"></span><span className="icon-name">3.0〜5.0m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-takashio-lv4"></span><span className="icon-name">5.0〜10m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-takashio-lv5"></span><span className="icon-name">10〜20m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-takashio-lv6"></span><span className="icon-name">20m〜</span>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="gnav-list-ac">
                <p className="f-check">
                    <input type="checkbox" id="cb02-tsunami" checked={layers.tsunami} onChange={e => dispatch(toggleLayerVisible("tsunami"))} />
                    <label htmlFor="cb02-tsunami">津波浸水想定</label></p>
                <div className="ac-item ac-hanrei" id="ac-tsunami" onClick={(e) => {toggleAccordion(e, "ac-tsunami")}}></div>
                <ul>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-tsunami-lv1"></span><span className="icon-name">0〜0.5m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-tsunami-lv2"></span><span className="icon-name">0.5〜3.0m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-tsunami-lv3"></span><span className="icon-name">3.0〜5.0m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-tsunami-lv4"></span><span className="icon-name">5.0〜10m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-tsunami-lv5"></span><span className="icon-name">10〜20m</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-tsunami-lv6"></span><span className="icon-name">20m〜</span>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="gnav-list-ac">
                <p className="f-check">
                    <input type="checkbox" id="cb02-dosekiryukeikaikuiki" checked={layers.dosekiryukeikaikuiki} onChange={e => dispatch(toggleLayerVisible("dosekiryukeikaikuiki"))} />
                    <label htmlFor="cb02-dosekiryukeikaikuiki">土砂災害警戒区域<br />（土石流）</label></p>
                <div className="ac-item ac-hanrei" id="ac-dosekiryukeikaikuiki" onClick={(e) => {toggleAccordion(e, "ac-dosekiryukeikaikuiki")}}></div>
                <ul>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-dosekiryu-lv1"></span><span className="icon-name">警戒区域</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-dosekiryu-lv2"></span><span className="icon-name">特別警戒区域</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="gnav-list-ac">
                <p className="f-check">
                    <input type="checkbox" id="cb02-kyukeishakeikaikuiki" checked={layers.kyukeishakeikaikuiki} onChange={e => dispatch(toggleLayerVisible("kyukeishakeikaikuiki"))} />
                    <label htmlFor="cb02-kyukeishakeikaikuiki">土砂災害警戒区域<br />（急傾斜地の崩壊）</label></p>
                <div className="ac-item ac-hanrei" id="ac-kyukeishakeikaikuiki" onClick={(e) => {toggleAccordion(e, "ac-kyukeishakeikaikuiki")}}></div>
                <ul>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-keisha-lv1"></span><span className="icon-name">警戒区域</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-keisha-lv2"></span><span className="icon-name">特別警戒区域</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="gnav-list-ac">
                <p className="f-check">
                    <input type="checkbox" id="cb02-jisuberikeikaikuiki" checked={layers.jisuberikeikaikuiki} onChange={e => dispatch(toggleLayerVisible("jisuberikeikaikuiki"))} />
                    <label htmlFor="cb02-jisuberikeikaikuiki">土砂災害警戒区域<br />（地すべり）</label></p>
                <div className="ac-item ac-hanrei" id="ac-jisuberikeikaikuiki" onClick={(e) => {toggleAccordion(e, "ac-jisuberikeikaikuiki")}}></div>
                <ul>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-jisuberi-lv1"></span><span className="icon-name">警戒区域</span>
                        </div>
                    </li>
                    <li>
                        <div className="ac-hanrei-item">
                            <span className="icon-marker icon-no h-jisuberi-lv2"></span><span className="icon-name">特別警戒区域</span>
                        </div>
                    </li>
                </ul>
            </div>
            
        </>
    );
}

export default NewLayerOptions;
