import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { MyAnalytics, PageTopButton } from '../atoms';
import { Footer, Header } from '../blocks';
import { fetchUserMarkers, fetchUserDrawing } from '../store/actions';
import LogoImg from '../assets/img/logo.png';

const MyPage = props => {
  const loginState = useSelector(state => state.login);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (loginState.isLogin) {
      dispatch(fetchUserMarkers());
      dispatch(fetchUserDrawing());
    }
  }, []);

  return (
    <>
      <MyAnalytics />
      <Header type="normal" />
      <div className="top-container is-mypage">
        <div className="top-contents">
          <ul className="top-inner">
            <li><h1 className="main-title"><img src={LogoImg} alt="くらしき防災オープンデータ　防災dataeye" /></h1></li>
            <li>
              <h2>{loginState.userInfo.group.name}</h2>
              <ul className="map-btn">
                <li><a className="c-btn c-btn-line c-btn-fixed" onClick={() => {
                  history.push("/bousai")
                }}>地区防災マップ {loginState.isLogin && `(${loginState.userInfo.group.name})`}</a></li>
                <li><a className="c-btn c-btn-line c-btn-fixed" onClick={() => {
                  history.push("/chat")
                }}>関係者に連絡</a></li>
                <li><a className="c-btn c-btn-line c-btn-fixed" onClick={() => {
                  history.push("/bousaiplans")
                }}>地区防災計画様式集</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
      <PageTopButton />
    </>
  )
}

export default MyPage;
