import 'leaflet/dist/leaflet.css';
import './App.css';
import './assets/sass/style.scss'
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from "./store";
import { MyRouter } from './modules';

function App() {
  return (
    <div id="app">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MyRouter />
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
