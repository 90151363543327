import React from 'react';
import { useDispatch } from 'react-redux';
import { setCirclePosition, setCenter, setZoom } from '../../store/actions';
import {
    TileLayer,
    ZoomControl,
    LayersControl,
    ScaleControl,
    useMapEvents
} from 'react-leaflet';
import commonLib from '../../config/commonLib';

const MapController = props => {
    const dispatch = useDispatch();
    const map = useMapEvents({
        click(e) {
            dispatch(setCirclePosition([e.latlng.lat, e.latlng.lng]));
        },
        moveend() {
            dispatch(setZoom(map.getZoom()));
            dispatch(setCenter(map.getCenter()));
        },
        zoomend() {
            dispatch(setZoom(map.getZoom()));
            dispatch(setCenter(map.getCenter()));
        }
    })
    const layers = commonLib.mapConfig.baseLayers;
    return (
        <>
            <LayersControl>
                {layers.map((layer, idx) => (
                    <LayersControl.BaseLayer checked={layer.checked} name={layer.name} key={layer.name + idx.toString()} zIndex={0}>
                        <TileLayer
                            url={layer.url}
                            zIndex={0}
                            maxZoom={layer.maxZoom} maxNativeZoom={layer.maxNativeZoom}
                        />
                    </LayersControl.BaseLayer>
                ))}
            </LayersControl>
            <ScaleControl position="bottomleft" imperial={false} />
            <ZoomControl position="bottomright" />
        </>
    )
}

export default MapController;