import {UPDATE_SHAPE_TYPE, TOGGLE_LAYER_VISIBLE, CLEAR_STATE} from './actions';

const initialState = {
    shapeType: null,
    kasen: false,
    kasenRank1: false,
    kasenRank2: false,
    kasenRank3: false,
    kasenRank4: false,
    sabou: false,
    tokubetsu: false,
    keikai: false,
    naisui: false,
    kojima: false,
    yoshioka_n: false,
    yoshioka_r_: false,
    katashima_: false,
    takahashi_r: false,
    bounds: false,
    flood_max: false, // 洪水浸水想定区域（想定最大規模）
    hightide: false, // 高潮浸水想定区域
    tsunami: false, // 津波浸水想定
    dosekiryukeikaikuiki: false, // 土砂災害警戒区域<br />（土石流）
    kyukeishakeikaikuiki: false, // 土砂災害警戒区域<br />（急傾斜地の崩壊）
    jisuberikeikaikuiki: false, // 土砂災害警戒区域<br />（地すべり）
}

const shapeState = (state = initialState, action) => {
    switch(action.type){
        case UPDATE_SHAPE_TYPE:
            return {
                ...state,
                shapeType: action.payload
            };
        case TOGGLE_LAYER_VISIBLE:

            // 単純な1メニューの場合はelse句でtoggleの反映を行なっている。
            
            if (action.payload.layerType.match(/kasen/)){
                if(action.payload.layerType === "kasen"){
                    let nextVisibility = !state[action.payload.layerType];
                    return {
                        ...state,
                        [action.payload.layerType]: nextVisibility,
                        kasenRank1: nextVisibility,
                        kasenRank2: nextVisibility,
                        kasenRank3: nextVisibility,
                        kasenRank4: nextVisibility,
                    }
                } else {
                    if (state["kasen"] === true){
                        let tmpKasenList = ["kasenRank1","kasenRank2","kasenRank3","kasenRank4"]
                        let kasenListIndex = tmpKasenList.indexOf(action.payload.layerType)
                        if(kasenListIndex >=0){
                            tmpKasenList.splice(kasenListIndex,1)
                        }
                        let kasenFlag = false
                        for (let kasenKey of tmpKasenList){
                            if(state[kasenKey] === true){
                                kasenFlag = true
                            }
                        }
                        if (state[action.payload.layerType] === false || kasenFlag ===true){
                            return {
                                ...state,
                                [action.payload.layerType]: !state[action.payload.layerType]
                            }
                        } else{
                            return {
                                ...state,
                                kasen:false,
                                [action.payload.layerType]: !state[action.payload.layerType]
                            }
                        }                    
                    } else{
                        return {
                            ...state,
                            kasen : true,
                            [action.payload.layerType]: !state[action.payload.layerType]
                        }
                    }
                }
            } else if (action.payload.layerType === "sabou" || action.payload.layerType === "tokubetsu" || action.payload.layerType === "keikai"){
                if(action.payload.layerType === "sabou"){
                        let nextVisibility = !state[action.payload.layerType];
                        return {
                            ...state,
                            [action.payload.layerType]: nextVisibility,
                            tokubetsu: nextVisibility,
                            keikai: nextVisibility,
                        }
                } else {
                    if (state["sabou"] == true){
                        let tmpSabouList = ["tokubetsu","keikai"]
                        let sabouListIndex = tmpSabouList.indexOf(action.payload.layerType)
                        if(sabouListIndex >=0){
                            tmpSabouList.splice(sabouListIndex,1)
                        }
                        let sabouFlag = false
                        for (let sabouKey of tmpSabouList){
                            if(state[sabouKey] === true){
                                sabouFlag = true
                            }
                        }
                        if (state[action.payload.layerType] === false || sabouFlag ===true){
                            return {
                                ...state,
                                [action.payload.layerType]: !state[action.payload.layerType]
                            }
                        } else{
                            return {
                                ...state,
                                sabou:false,
                                [action.payload.layerType]: !state[action.payload.layerType]
                            }
                        } 
                    } else{
                        return {
                            ...state,
                            sabou : true,
                            [action.payload.layerType]: !state[action.payload.layerType]
                        }
                    }
                }
            } else if (
                action.payload.layerType === "naisui" || 
                action.payload.layerType === "kojima" || 
                action.payload.layerType === "yoshioka_n" || 
                action.payload.layerType === "yoshioka_r_" || 
                action.payload.layerType === "katashima_" || 
                action.payload.layerType === "takahashi_r"
                ){
                    if(action.payload.layerType === "naisui"){
                        let nextVisibility = !state[action.payload.layerType];
                        return {
                            ...state,
                            [action.payload.layerType]: nextVisibility,
                            kojima: nextVisibility,
                            yoshioka_n: nextVisibility,
                            yoshioka_r_: nextVisibility,
                            katashima_: nextVisibility,
                            takahashi_r: nextVisibility
                        }
                    } else {
                        if (state["naisui"] == true){
                            let tmpNaisuiList = ["kojima","yoshioka_n","yoshioka_r_","katashima_","takahashi_r"]
                            let naisuiListIndex = tmpNaisuiList.indexOf(action.payload.layerType)
                            if(naisuiListIndex >=0){
                                tmpNaisuiList.splice(naisuiListIndex,1)
                            }
                            let naisuiFlag = false
                            for (let naisuiKey of tmpNaisuiList){
                                if(state[naisuiKey] === true){
                                    naisuiFlag = true
                                }
                            }
                            if (state[action.payload.layerType] === false || naisuiFlag ===true){
                                return {
                                    ...state,
                                    [action.payload.layerType]: !state[action.payload.layerType]
                                }
                            } else{
                                return {
                                    ...state,
                                    naisui:false,
                                    [action.payload.layerType]: !state[action.payload.layerType]
                                }
                            }
                        } else{
                            return {
                                ...state,
                                naisui : true,
                                [action.payload.layerType]: !state[action.payload.layerType]
                            }
                        }
                    }
            } else{
                return {
                    ...state,
                    [action.payload.layerType]: !state[action.payload.layerType]
                }
            }
        case CLEAR_STATE:
            return initialState;
        default:
            return state;
    }
}

export default shapeState;