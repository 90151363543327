import React from 'react';
import { useMap } from 'react-leaflet';

const PanController = props => {
    const map = useMap();
    const upClick = () => {
        map.panBy([0, -100]);
    };
    const rightClick = () => {
        map.panBy([100, 0]);
    }
    const leftClick = () => {
        map.panBy([-100, 0]);
    }
    const downClick = () => {
        map.panBy([0, 100]);
    }
    return <div
        className={"my-leaflet-control"}
        style={{
            position: 'absolute',
            zIndex: 5000,
            top: "130px",
            right: '10px',
            height: "80px",
            width: "80px",
            backgroundColor: 'white',
            borderRadius: 300,
            color: "rgba(0, 0, 0, 0.2)",
            border: "solid",
            borderWidth: "2px",
        }}>
        <div style={{ marginLeft: '32px' }}>
            <button onClick={() => upClick()}>
                ▲
            </button>
        </div>
        <div style={{ marginTop: "5px", marginLeft: "7px" }}>
            <button onClick={() => leftClick()}>
                ◀︎
            </button>
            <button style={{ marginLeft: '32px' }} onClick={() => rightClick()}>
                ▶︎
                </button>
        </div>
        <div style={{ marginLeft: '32px' }}>
            <button onClick={() => downClick()}>
                ▼
            </button>
        </div>
    </div>;
}

export default PanController;