import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import commonLib from '../../config/commonLib';

const AdditionalMarkers = props => {
    const markerVisibleState = useSelector(state => state.markerVisible);
    const additionals = useSelector(state => state.markerData.additionals);
    const getIconUrl = marker => {
        let iconUrl = null;
        if (marker.type === "n01") {
            let category = marker.category < 10 ? `n0${marker.category}` : `n${marker.category}`;
            iconUrl = new L.Icon({
                iconUrl: commonLib[`${marker.file_url ? category + "P" : category}`],
                iconRetinaUrl: commonLib[`${marker.file_url ? category + "P" : category}`],
                iconSize: [27, 27],
                popupAnchor: [0, -23]
            });
        } else {
            iconUrl = new L.Icon({
                iconUrl: commonLib[`${marker.file_url ? marker.type + "P" : marker.type}`],
                iconRetinaUrl: commonLib[`${marker.file_url ? marker.type + "P" : marker.type}`],
                iconSize: [27, 27],
                popupAnchor: [0, -23]
            });
        }
        return iconUrl;
    };
    return (
        <>
            {markerVisibleState.regist && <>
                {additionals.map((marker, idx) => {
                    if (marker === null || marker === undefined) {
                        return null;
                    } // markerが不正な場合 null を返却
                    const center = [marker.center.lat, marker.center.lng];
                    let iconUrl = getIconUrl(marker);
                    return (
                        <Fragment key={idx.toString()}>{markerVisibleState[marker.type] &&
                            <Marker
                                position={center}
                                icon={iconUrl}
                                key={marker.type + idx.toString()}
                            >
                                <Popup>
                                    <form>
                                        <div className="pb-sm1">
                                            <dl className="dl-list is-modal-spot is-first">
                                                <dt>スポット名</dt>
                                                <dd>{marker.name}</dd>
                                            </dl>
                                            {marker.file_url &&
                                                <dl className="dl-list is-modal-spot is-first">
                                                    <dt>写真投稿(任意)</dt>
                                                    <dd>
                                                        <img src={marker.file_url} width={200} alt='icon' />
                                                    </dd>
                                                </dl>
                                            }
                                        </div>
                                        <div className='popup-btn'>
                                            <a className='c-btn c-btn-solid c-btn-xs' target='_blank' rel="noreferrer" href={'https://maps.google.com/maps?saddr=現在地&daddr=' + marker.center.lat + ',' + marker.center.lng}>
                                                行き方を調べる(GoogleMapが開きます)
                                            </a>
                                        </div>
                                    </form>
                                </Popup>
                            </Marker>
                        }</Fragment>
                    );
                })} </>
            }
        </>
    )
}

export default AdditionalMarkers;
