import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.vectorgrid';

const VectorGrid = props => {
    const map = useMap();
    useEffect(() => {
        const tmpLayer = L.vectorGrid.protobuf("https://bousai-dataeye.jp/tile/data/h27ka33202_line/{z}/{x}/{y}.pbf", {
            maxZoom: 18,
            rendererFactory: L.canvas.tile,
            vectorTileLayerStyles: {
                gs_map: {
                    color: "red"
                }
            },
            zIndex: 6000
        }).addTo(map);
        return () => { map.removeLayer(tmpLayer) };
    }, [])
    return null;
}

export default VectorGrid;