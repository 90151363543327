// APIのURL
const localAPI = 'https://bousai-dataeye.jp/api/';

// baseURL
const baseURL ='https://area.bousai-dataeye.jp'
// const baseURL ='http://127.0.0.1:6992'

const GOOGLE_ANALYTICS_ID = "UA-192839275-1"

const shoukasenZoomLimit = 15

export default {
  localAPI,
  baseURL,
  GOOGLE_ANALYTICS_ID,
  shoukasenZoomLimit
}
