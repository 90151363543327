import React from 'react';
import {
    BackButton,
} from '../components/map';
import {
    ConfigButton,
    HeaderTitle,
    HomeButton,
    MenuButton,
    PrintMenu
} from '../atoms';
import {
    Navigator
} from '../blocks';

const Header = props => {
    const navigators = {
        "normal": <Navigator type="normal" />,
        "map": <Navigator type="map" />,
        "spotregist": null,
        "freedrawing": null,
        "printing": null
    };
    const headerTitles = {
        "normal": <HeaderTitle type="normal" isOnly={false} />,
        "map": <HeaderTitle type="bousai" isOnly={false} />,
        "spotregist": <HeaderTitle type="spotregist" isOnly={true} />,
        "freedrawing": <HeaderTitle type="freedrawing" isOnly={true} />,
        "printing": <HeaderTitle type="bousai" isOnly={true} />
    };
    const buttons = {
        "normal": <><MenuButton /><HomeButton /></>,
        "map": <><MenuButton /><ConfigButton /><HomeButton /></>,
        "spotregist": <BackButton freeDrawing={false} />,
        "freedrawing": <BackButton freeDrawing={true} />,
        "printing": <BackButton freeDrawing={false} />
    };
    const others = {
        "normal": null,
        "map": null,
        "spotregist": null,
        "freedrawing": null,
        "printing": <PrintMenu />
    };
    return (
        <header className={`header ${props.type === "map" ? "" : "is-mypage"}`}>
            {navigators[props.type]}
            {headerTitles[props.type]}
            {buttons[props.type]}
            {others[props.type]}
        </header>
    );
};

export default Header;