
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    MapContainer,
    GeoJSON
} from "react-leaflet";
import {
    MapController,
    Markers,
    AdditionalMarkers,
    Rasters,
    MarkerHanrei,
    LayerHanrei,
    PaintPolygon,
    PanController,
    VectorGrid
} from '../map';
import commonLib from '../../config/commonLib';
import {
    CurrentPosButton,
    RerenderButton
} from '../../atoms';
import {
    HanreiAsset,
    SearchAsset
} from '../../blocks'
import { fetchUserDrawing, fetchUserMarkers } from '../../store/actions';

const MapBase = props => {
    const login = useSelector(state => state.login);
    const location = useSelector(state => state.center);
    const geoJson = useSelector(state => state.ppc.geojson);
    const freeDrawingVisibility = useSelector(state => state.markerVisible.freeDrawing);
    const hanreiVisibility = useSelector(state => state.hanrei.visibility);
    const bounds = useSelector(state => state.shape.bounds);
    const dispatch = useDispatch();
    let center = commonLib.mapConfig.center;
    let zoom = location.zoom || commonLib.mapConfig.zoom;
    if (login.isLogin) {
        center = location.center || login.userInfo.center;
    }
    useEffect(() => {
        if (login.isLogin) {
            dispatch(fetchUserDrawing());
            dispatch(fetchUserMarkers());
        }
    }, [])
    return (
        <div>
            <main className={(!props.freeDrawing) ? "main" : "main is-first-layers-icon"}>
                <div className="main-inner">
                    {
                        !props.freeDrawing &&
                        (
                            login.isLogin
                                ? hanreiVisibility && <MarkerHanrei />
                                : hanreiVisibility && <LayerHanrei />
                        )
                    }
                    <MapContainer
                        tap={false}
                        id="map"
                        minZoom={commonLib.mapConfig.minZoom}
                        zoom={zoom}
                        maxZoom={commonLib.mapConfig.maxZoom}
                        center={center}
                        gestureHandling={commonLib.mapConfig.gestureHandling}
                        preferCanvas={commonLib.mapConfig.preferCanvas}
                        zoomControl={false}
                        scrollWheelZoom={commonLib.mapConfig.scrollWheelZoom}
                        dragging={!props.freeDrawing}
                    >
                        {props.freeDrawing && <PaintPolygon />}
                        {(props.freeDrawing || props.printing) && <PanController />}
                        <CurrentPosButton />
                        <RerenderButton />
                        <MapController />
                        <Markers type={props.type} />
                        {login.isLogin && <AdditionalMarkers />}
                        {(login.isLogin && freeDrawingVisibility && !props.freeDrawing && geoJson !== null) && <GeoJSON key="my-geojson" data={geoJson} />}
                        <Rasters />

                        {bounds && <VectorGrid />}
                        {(!props.freeDrawing) && <HanreiAsset />}

                        <SearchAsset />
                    </MapContainer>
                </div>
            </main>
        </div>
    )
}

export default MapBase;
