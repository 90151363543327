import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { login } from '../store/actions';
import { MyAnalytics, PageTopButton } from '../atoms';
import LogoImg from '../assets/img/logo.png';
import FooterLogoImg from '../assets/img/footer_logo.png';

const Login = props => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    const execLogin = async () => {
        const response = await dispatch(login(email, password))
            .then(res => {
                return res;
            }).catch(err => {
                return "error";
            });
        if (response === "failed" || response === "error") {
            setPassword("");
        } else {
            history.push("/mypage");
        }
    }
    return (
        <>
            <MyAnalytics />
            <div className="top-container is-login">
                <div className="top-contents is-login">
                    <div className="login-form">
                        <h1 className="login-title"><a onClick={() => {
                            history.push("/")
                        }}><img src={LogoImg} alt="くらしき防災オープンデータ　防災dataeye" /></a></h1>
                        <h2>ログイン</h2>
                        <form>
                            <div className="top-login">
                                <dl className="dl-list is-login">
                                    <dt>メールアドレス</dt>
                                    <dd>
                                        <input type="email" placeholder="" value={email} onChange={e => setEmail(e.target.value)} className="f-w-lg" />
                                    </dd>
                                </dl>
                                <dl className="dl-list is-login u-mb-sm">
                                    <dt>パスワード</dt>
                                    <dd>
                                        <input type="password" placeholder="" value={password} onChange={e => setPassword(e.target.value)} className="f-w-lg" />
                                        {/* <span className="f-error">※エラーメッセージ</span> */}
                                    </dd>
                                </dl>
                                <p className="u-txt-c"><button type="button" className="c-btn c-btn-solid c-btn-lg" onClick={() => execLogin()}>ログイン</button></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <footer>
                <div className="footer-inner">
                    <div className="footer-inner__l">
                        <ul className="footer-nav">
                            <li>メニュー</li>
                            <li><a href="/bousai">防災オープンデータマップ</a></li>
                            {/* <li><a href="/mapB">もしもの時の行動シミュレーション</a></li> */}
                            <li><a href="https://takahashi-riv.dataeye.jp/pages/data/mabi" className="link-tb">防災データから見えるもの</a></li>
                            <li><a href="https://kurashiki.dataeye.jp/datasets?tag=%E9%98%B2%E7%81%BD" className="link-tb">防災オープンデータ</a></li>
                            <li><a href="https://bousai-portal.city.kurashiki.okayama.jp/" className="link-tb" target="_blank">倉敷防災ポータル</a></li>
                        </ul>
                        <dl className="footer-contact">
                            <dt>一般社団法人データクレイドル</dt>
                            <dd><a href="https://d-cradle.or.jp/contact/contact_register/index.html" target="_blank">ご相談・お問い合わせ</a></dd>
                        </dl>
                    </div>
                    <div className="footer-inner__r">
                        <ul className="footer-subnav">
                            <li><a href="/">ホーム</a></li>
                        </ul>
                        <p className="footer-logo"><a href="https://dataeye.jp/" target="_blank" className="footer-logo"><img src={FooterLogoImg} alt="dataeye" /></a></p>
                    </div>
                </div>
            </footer>
            <PageTopButton />
        </>
    )
}

export default Login;
