import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import markerDataReducer from './markerData';
import markerVisibleReducer from './markerVisible';
import shapeReducer from './shape';
import circleReducer from './circle';
import centerReducer from './center';
import loginReducer from './login';
import hanreiReducer from './hanrei';
import ppcReducer from './ppc';
import formatFileReducer from './formatFiles';
import linkDataReducer from './linkData';
import {CLEAR_STATE} from './actions';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ["ppc"]
};

const rootReducer = combineReducers({
    markerData: markerDataReducer,
    markerVisible: markerVisibleReducer,
    shape: shapeReducer,
    circle: circleReducer,
    center: centerReducer,
    login: loginReducer,
    hanrei: hanreiReducer,
    ppc: ppcReducer,
    formatFile: formatFileReducer,
    linkData: linkDataReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
    persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(thunk)
)

export const persistor = persistStore(store)

export default store;
