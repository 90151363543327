import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { MyAnalytics, PageTopButton } from '../atoms';
import { Footer, Header } from '../blocks';
import { fetchFormatFiles } from "../store/actions";

const BousaiPlans = props => {
    const formatFileState = useSelector(state => state.formatFile);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchFormatFiles());
    }, [dispatch]);

    return (
        <>
            <MyAnalytics />
            <Header type="normal" />
            <div className="low-container">
                <div className="low-contents">
                    <div className="low-contents-inner">
                        <h1 className="page-title">地区防災計画様式集</h1>
                        <p className="u-pb-sm">各ファイルをダウンロードしてご利用ください。</p>
                        {
                            formatFileState.categories.map((category, categoryIdx) => {
                                return (
                                    <section className="sec" key={categoryIdx}>
                                        <h2>{category.name}</h2>
                                        {
                                            category.format_sub_categories.map((subCategory, subCategoryIdx) => {
                                                return (
                                                    (subCategory.format_files.length > 0) &&
                                                    <Fragment key={subCategoryIdx}>
                                                        <h3><span>■</span>{subCategory.name}</h3>
                                                        <ul className="p-num-list">
                                                            {
                                                                subCategory.format_files.map((formatFile, fileIdx) => {
                                                                    return (
                                                                        <li key={fileIdx}>
                                                                            <a href={formatFile.url} download={formatFile.original_file_name} target="_blank">{formatFile.name}</a>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </section>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Footer />
            <PageTopButton />
        </>
    )
}

export default BousaiPlans;
