import React from 'react';
import { MyAnalytics, PageTopButton } from '../atoms';
import LogoImg from '../assets/img/logo.png';
import FooterLogoImg from '../assets/img/footer_logo.png';

const Index = props => {
    return (
        <>
            <MyAnalytics />
            <div className="top-container">
                <div className="top-contents">
                    <ul className="top-inner">
                        <li><h1 className="main-title"><img src={LogoImg} alt="防災オープンデータマップ" /></h1></li>
                        <li>
                            <h2>倉敷市</h2>
                            <ul className="map-btn">
                                <li><a href="/bousai" className="c-btn c-btn-line c-btn-fixed">防災オープンデータマップ</a></li>
                                <li><a href="/login" className="c-btn c-btn-line c-btn-fixed">各地域ログイン</a></li>
                                <li><a href="https://www.youtube.com/playlist?list=PLK9dAk7oPYIgwKBal9PnuuHFCgsNMafFu" class="c-btn c-btn-line c-btn-fixed"><span class="c-btn-icon">動画マニュアル</span></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            {/*<div class="container is-about">*/}
            {/*    <div class="contents">*/}
            {/*        <h2 class="page-title">このサイトについて</h2>*/}
            {/*        /!* <p class="about-txt">コメントが入ります。くらしき防災オープンデータ 防災data eyeは、日頃の備えを地域の皆さんで共有・話し合ってもらうことを目的として・・・</p> *!/*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="container is-contents">
                <div className="contents">
                    <ul className="de-contents">
                        <li>
                            <a href="https://takahashi-riv.dataeye.jp/pages/data/mabi" target="_blank">
                                <div className="dashboard"></div>
                                <dl className="dec-in">
                                    <dt>防災データから見えるもの</dt>
                                    <dd>防災データを分析した結果を図やグラフなどを活用して、わかりやすくお伝えします。地域の現状や課題の「見える」化を目指します。</dd>
                                </dl>
                            </a>
                        </li>
                        <li>
                            <a href="https://kurashiki.dataeye.jp/datasets?tag=%E9%98%B2%E7%81%BD" target="_blank">
                                <div className="datacatalog"></div>
                                <dl className="dec-in">
                                    <dt>防災オープンデータ</dt>
                                    <dd>オープンデータを検索・ダウンロードできる「カタログ」サービスです。ダウンロードしたデータは、商用・非商用を問わず、誰でも自由に再利用できます。</dd>
                                </dl>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <footer>
                <div className="footer-inner">
                    <div className="footer-inner__l">
                        <ul className="footer-nav">
                            <li>メニュー</li>
                            <li><a href="/bousai">防災オープンデータマップ</a></li>
                            <li><a href="https://takahashi-riv.dataeye.jp/pages/data/mabi" className="link-tb">防災データから見えるもの</a></li>
                            <li><a href="https://kurashiki.dataeye.jp/datasets?tag=%E9%98%B2%E7%81%BD" className="link-tb">防災オープンデータ</a></li>
                            <li><a href="https://bousai-portal.city.kurashiki.okayama.jp/" class="link-tb" target="_blank">倉敷防災ポータル</a></li>
                        </ul>
                        <dl className="footer-contact">
                            <dt>一般社団法人データクレイドル</dt>
                            <dd><a href="https://d-cradle.or.jp/contact/contact_register/index.html" target="_blank">ご相談・お問い合わせ</a></dd>
                        </dl>
                    </div>
                    <div className="footer-inner__r">
                        <ul className="footer-subnav">
                            <li><a href="/login">ログイン</a></li>
                        </ul>
                        <p className="footer-logo"><a href="https://dataeye.jp/" target="_blank" className="footer-logo"><img src={FooterLogoImg} alt="dataeye" /></a></p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Index;
