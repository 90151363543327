import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMap } from 'react-leaflet';
import { requestLocationInfoByWord } from '../store/actions';
import $ from 'jquery';

const toggleModal = () => {
    let modal = document.getElementById("modal-search");
    $(modal).fadeToggle();
    return false;
};

const SearchButton = props => {
    return (
        <div className="l-map-btn l-map-btn--search">
            <a className="js-modal-open" data-target="modal-search" onClick={() => toggleModal()} />
        </div>
    );
};

const SearchModal = props => {
    const [keyword, setKeyword] = useState("");
    const dispatch = useDispatch();
    const map = useMap();
    const post = async () => {
        const response = await dispatch(requestLocationInfoByWord(keyword))
            .then(res => {
                return res;
            })
            .catch(err => {
                return "error";
            })
        if (response === "none") {
            window.alert("検索結果がありません。");
        } else if (response === "error") {
            window.alert("検索中にエラーが発生しました。やり直してください。")
        } else {
            map.panTo(response);
        }
        return;
    }
    return (
        <div id="modal-search" className="modal js-modal">
            <div className="modal-bg js-modal-close" onClick={() => toggleModal()} />
            <div className="modal-content">
                <div className="modal-inner is-search">
                    <div className="u-txt-c">
                        <ul className="search-location">
                            <li>
                                <input
                                    type="text"
                                    className="f-w-md"
                                    placeholder="郵便番号や住所を入力"
                                    value={keyword}
                                    onChange={e => setKeyword(e.target.value)}
                                />
                            </li>
                            <li>
                                <button type="button" className="c-btn-search" onClick={() => post()}>
                                    検索
                                    </button>
                            </li>
                        </ul>
                    </div>
                    <a className="modal-close js-modal-close" onClick={() => toggleModal()}>
                        <span className="icon">
                            ×
                            </span>
                    </a>
                </div>
            </div>
        </div>
    );
};

const SearchAsset = props => {
    return (
        <>
            <SearchButton />
            <SearchModal />
        </>
    );
};

export default SearchAsset;