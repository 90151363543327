import React from 'react';
import { MyAnalytics, PageTopButton } from '../atoms';
import { Footer, Header } from '../blocks';
import { useSelector, useDispatch } from "react-redux";
import { changeUserGroup, setCenter, fetchUserMarkers, fetchUserDrawing } from '../store/actions';

const UserInfo = props => {
  const login = useSelector(state => state.login);
  const dispatch = useDispatch();
  const handleChange = async event => {
    const selected = login.groups.find(el => {
      return el.id == event.target.value
    })
    dispatch(changeUserGroup(selected));
    const response = await dispatch(fetchUserMarkers())
      .then(res => {
        return res;
      }).catch(err => {
        return "error";
      });
    if (response !== "error") {
      dispatch(setCenter({
        lat: selected.lat,
        lng: selected.lng
      }));
    }
    dispatch(fetchUserDrawing());
  }

  const options = login.groups.map(
    (n) => (
      <option key={n.id} value={n.id}>
        {n.name}
      </option>
    )
  );

  return (
    <>
      <MyAnalytics />
      <Header type="normal" />
      <div className="low-container">
        <div className="low-contents">
          <div className="low-contents-inner">
            <h1 className="page-title">ユーザー情報</h1>
            <section>
              <label>
                表示中のグループ:
                <select value={login.userInfo.group.id} onChange={(e) => { handleChange(e) }}>
                  {options}
                </select>
              </label>
            </section>
          </div>
        </div>
      </div>
      <Footer />
      <PageTopButton />
    </>
  )
}

export default UserInfo;
