import React from 'react';
import { useHistory } from 'react-router'

const BackButton = props => {
    const history = useHistory();
    return (
        <>
            <div className="h-back">
                <div className="gnav-back-inner">
                    <a onClick={() => {
                        if (props.freeDrawing) {
                            history.push("/bousai");
                        } else {
                            history.goBack();
                        }
                    }}><span className="h-back-icon"></span><span className="h-back-txt">もどる</span></a>
                </div>
            </div>
        </>
    )
}

export default BackButton;