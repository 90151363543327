import React from 'react';
import { useHistory } from 'react-router';
import { MyAnalytics, PageTopButton } from '../atoms';
import commonLib from "../config/commonLib";
import {
  Marker,
  Popup,
  MapContainer,
  ScaleControl,
  TileLayer
} from "react-leaflet";
import L from "leaflet";
import markerIcon from '../assets/img/icon_pin2.png';
import { Footer, Header } from '../blocks';

const ChatMap = props => {
  const history = useHistory();

  const iconUrl = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    iconSize: [29, 47],
    popupAnchor: [0, -50]
  });

  const regex = /([\n\r])/g
  const nl2br = (str) => {
    return str.split(regex).map(line => {
      return line.match(regex) ? <br /> : line
    })
  }

  return (
    <>
      <MyAnalytics />
      <Header type="normal" />
      <div className="low-container">
        <div className="low-contents">
          <div className="low-contents-inner">

            {true &&
              <>
                <a onClick={() => { history.goBack() }} className="c-btn c-btn-xs">戻る</a>
                <br />
                <div>
                  {history.location.postData.file_url && <><img src={history.location.postData.file_url} max-width={380} alt='icon' /><br /></>}
                  {nl2br(history.location.postData.content)}
                </div>
                <MapContainer
                  tap={false}
                  id="chat-map"
                  minZoom={commonLib.mapConfig.minZoom}
                  zoom={commonLib.mapConfig.zoom}
                  maxZoom={commonLib.mapConfig.maxZoom}
                  center={history.location.postData}
                  gestureHandling={commonLib.mapConfig.gestureHandling}
                  preferCanvas={commonLib.mapConfig.preferCanvas}
                  zoomControl={commonLib.mapConfig.zoomControl}
                  scrollWheelZoom={commonLib.mapConfig.scrollWheelZoom}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <ScaleControl position="bottomleft" imperial={false} />
                  <Marker
                    icon={iconUrl}
                    position={history.location.postData}>
                    <Popup>
                      {nl2br(history.location.postData.content)}
                    </Popup>
                  </Marker>
                </MapContainer>
              </>
            }

          </div>
        </div>
      </div>
      <Footer />
      <PageTopButton />
    </>
  )
}

export default ChatMap;
