import React from 'react';
import MCautionAreaImg from '../../assets/img/marker/m_caution-area.png'
import MSCautionAreaImg from '../../assets/img/marker/m_s-caution-area.png'

const LayerHanrei = props => {
    return (
        <>
            <div className="hanrei">
                <ul className="hanrei-list">
                    <li><dl><dt><span><img src={MSCautionAreaImg} /></span></dt><dd>特別警戒区域</dd></dl></li>
                    <li><dl><dt><span><img src={MCautionAreaImg} /></span></dt><dd>警戒区域</dd></dl></li>
                </ul>
            </div>
        </>
    )
}

export default LayerHanrei;