import React from 'react';
import { useHistory } from 'react-router';
import * as $ from 'jquery';

const ConfigButton = props => {
    let body = document.body;
    const history = useHistory();
    const jumpTo = page => {
        history.push(page);
        body.classList.remove('nav-open');
        return;
    };
    return (
        <>
            <div className="h-setting">
                <div className="h-setting-inner" onClick={() => {
                    body.classList.toggle('setting-open');
                }}>
                    <span className="h-set" />
                    <span className="h-set-txt">
                        地図設定
                    </span>
                </div>
                <div className="h-subnav">
                    <ul className="h-setting-list">
                        <li>
                            <a className="js-modal-open" data-target="modal02" onClick={() => {
                                jumpTo("/print");
                            }}>
                                <dl className="h-sl-item">
                                    <dt className="i-map" />
                                    <dd>
                                        地図を印刷
                                    </dd>
                                </dl>
                            </a>
                        </li>
                        <li>
                            <a className="js-modal-open" data-target="modal03" onClick={() => {
                                let modal = document.getElementById("modal03");
                                $(modal).fadeIn();
                                return false;
                            }}>
                                <dl className="h-sl-item">
                                    <dt className="i-qr" />
                                    <dd>
                                        地図を共有
                                    </dd>
                                </dl>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ConfigButton;