import React from 'react';
import { useHistory } from 'react-router';
import {
    FreeDrawingLeftMenu,
    NewLayerOptions,
    NewSpots,
} from '../components/map';

const NormalNavigator = props => {
    let body = document.body;
    const history = useHistory();
    const navList = [
        { page: "/bousai", name: "地区防災マップ" },
        { page: "/chat", name: "関係者に連絡" },
        { page: "/bousaiplans", name: "地区防災計画様式集" },
        { page: "/links", name: "お役立ちリンク集" },
        { page: "/userinfo", name: "ユーザー情報" }
    ];
    const jumpTo = page => {
        history.push(page);
        body.classList.remove('nav-open');
        return;
    };
    return (
        <ul className="gnav-mypage">
            {navList.map(nav =>
                <li key={nav.name}>
                    <a onClick={() => jumpTo(nav.page)}>
                        {nav.name}
                    </a>
                </li>
            )}
        </ul>
    );
};

const MapNavigator = props => {
    return (
        <div className="gnav-inner">
            <NewLayerOptions />
            <NewSpots />
            {/* <FreeDrawingLeftMenu /> */}
        </div>
    );
};

const Navigator = props => {
    const navigators = {
        "normal": <NormalNavigator />,
        "map": <MapNavigator />
    };
    return (
        <nav className="gnav">
            {navigators[props.type]}
        </nav>
    );
};

export default Navigator;